<template>
  <el-dialog v-model="isOpen" @close="$emit('update:modelValue', false)">
    <component
      :is="TypeFields[stateWidget.type]"
      :widget-data="stateWidget"
      :additions-data="stateAdditional"
      is-edit
      @update:autocomplete="autocompleteData = $event"
      @input:error="errorData = $event"
    />
    <template #footer>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button @click="$emit('update:modelValue', false)">
            {{ translateText('hubstr.editor.cancel') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button
            type="primary"
            :loading="load"
            :disabled="errorData"
            @click="submitHandler"
          >
            {{ translateText('hubstr.editor.apply') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, watch,
} from 'vue';
import { WidgetBody } from 'features/settings/mobile-editor/interfaces';
import {
  AdditionsList,
  createAutocompleteField, additionsGet,
  updateAdditionalField,
} from 'features/settings/mobile-editor/requests';
import TextSection from 'features/settings/mobile-editor/components/left-side/modals/slots/text-section.vue';
import ListLineRawAction
  from 'features/settings/mobile-editor/components/left-side/modals/slots/list-line-raw-action.vue';
import ListLine from 'features/settings/mobile-editor/components/left-side/modals/slots/list-line.vue';
import { translate, useTranslate } from 'magner';

enum TypeFields {
  text_section_widget = 'text-section',
  list_line_widget = 'list-line',
  list_line_raw_action_widget = 'list-line-raw-action',
}

export default defineComponent({
  name: 'EditFieldModal',
  components: {
    ListLineRawAction, TextSection, ListLine,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object as PropType<WidgetBody>,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup (props, { emit }) {
    const isOpen = ref(props.modelValue);
    const load = ref(false);

    const stateWidget = ref(props.data);
    const stateAdditional = ref(AdditionsList.find((i) => Object.values(stateWidget.value).includes(i.formCode)));
    const autocompleteData = ref();
    const errorData = ref(false);

    const submitHandler = async () => {
      load.value = true;
      const res = await updateAdditionalField(stateAdditional.value);
      if (res.error) {
        load.value = false;
        return;
      }
      if (autocompleteData.value?.values.length) {
        await createAutocompleteField({
          field: stateAdditional.value?.code,
          values: autocompleteData.value.values,
        });
      }
      await additionsGet('');
      load.value = false;
      emit('update:modelValue', false);
    };

    watch(() => props.modelValue, (newValue) => {
      isOpen.value = newValue;
      emit('update:modelValue', newValue);
    });

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      TypeFields,
      isOpen,
      load,
      stateWidget,
      stateAdditional,
      autocompleteData,
      errorData,
      submitHandler,
      translateText,
    };
  },
});
</script>

<style scoped>

</style>
