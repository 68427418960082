<template>
  <el-form
    ref="formRef"
    :model="createData"
    @change="emitHandler(formRef)"
    @input="emitHandler(formRef)"
  >
    <el-form-item
      :label="translateText('hubstr.editor.modal.field_name')"
      prop="description"
      :rules="[
        { required: true, message: translateText('hubstr.editor.errors.required_field'), },
      ]"
    >
      <el-input
        v-model="createData.description"
        :maxlength="40"
      />
    </el-form-item>
    <el-form-item :label="translateText('hubstr.editor.modal.data_type.label')">
      <el-radio-group v-model="createData.variableType" :disabled="isEdit">
        <el-radio label="text">{{ translateText('hubstr.editor.modal.data_type.text') }}</el-radio>
        <el-radio label="link">{{ translateText('hubstr.editor.modal.data_type.link') }}</el-radio>
        <el-radio label="datetime">{{ translateText('hubstr.editor.modal.data_type.date_time') }}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-checkbox
        v-if="createData.variableType === 'text' || createData.variableType === 'array'"
        v-model="createData.isMultiline"
        :disabled="formData.hasAutoComplete"
        :label="translateText('hubstr.editor.modal.long_text')"
      />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="createData.isUserRequired" :label="translateText('hubstr.editor.fields.required_app')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="createData.isAdminRequired" :label="translateText('hubstr.editor.fields.required_admin')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.hasPrivacy" :label="translateText('hubstr.editor.fields.has_privacy')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox
        v-if="createData.variableType === 'text' || createData.variableType === 'array'"
        v-model="formData.hasAutoComplete"
        :label="translateText('hubstr.editor.modal.add_autocomplete')"
        :disabled="createData.isMultiline"
      />
    </el-form-item>
    <form-autocomplete
      v-if="formData.hasAutoComplete"
      :field-code="createData.code"
      @update:autocomplete="$emit('update:autocomplete', $event)"
    />
  </el-form>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import { CreateField } from 'features/settings/mobile-editor/interfaces';
import FormAutocomplete, {
} from 'features/settings/mobile-editor/components/left-side/modals/components/form-autocomplete.vue';
import { TranslatesList } from 'features/settings/mobile-editor/requests';
import { FormInstance } from 'element-plus';
import { translate, useTranslate } from 'magner';
import translit from '~/features/settings/mobile-editor/utils/translit';

interface TextSection {
  type: 'text_section_widget',
  title: string,
  text: string,
  required: boolean,
  hasPrivacy: boolean,
  hasAutoComplete: boolean,
  name?: string,
}

export default defineComponent({
  name: 'TextSection',
  components: { FormAutocomplete },
  emits: ['input', 'input:createData', 'input:error', 'update:autocomplete'],
  props: {
    widgetData: {
      type: Object as PropType<TextSection>,
      default: () => {},
    },
    additionsData: {
      type: Object as PropType<any>,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const formRef = ref<FormInstance>();

    const formData = reactive<TextSection>(props.widgetData || {
      type: 'text_section_widget',
      title: '',
      text: '',
      required: false,
      hasPrivacy: false,
      hasAutoComplete: false,
    });

    const createData = reactive<CreateField>(props.additionsData || {
      code: '',
      variableType: 'text',
      isUserRequired: false,
      isAdminRequired: false,
      format: 'DEFAULT',
      entityType: 'user',
      description: '',
      isMultiline: false,
    });

    const fieldBuilder = () => {
      const descriptionTrim = createData.description.trim();

      if (!props.isEdit) {
        createData.code = descriptionTrim ? `${createData.entityType}${translit(descriptionTrim, 'pascal')}` : '';
        formData.text = `data.additions.${createData.code}`;
      }
      formData.title = descriptionTrim || `lang.${translit(descriptionTrim, 'camel')}`;
      formData.required = createData.isAdminRequired;
    };

    const emitHandler = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          // submit
          emit('input:error', false);

          fieldBuilder();
          const outCreateData = reactive({
            ...createData,
            description: createData.description.trim(),
            variableType: formData.hasAutoComplete ? 'array' : createData.variableType,
          });

          emit('input', formData);
          emit('input:createData', outCreateData);
          return;
        }

        emit('input:error', true);
      });
    };

    /**
     * Принудительно с большой буквы
     * */
    watch(() => createData.description, (newValue) => {
      createData.description = newValue?.charAt(0).toUpperCase() + newValue.slice(1);
    });

    watch(() => createData.variableType, () => {
      if (createData.variableType !== 'text') {
        formData.hasAutoComplete = false;
        createData.isMultiline = false;
      }
      if (createData.variableType === 'link') {
        formData.name = 'link';
      }
    });
    watch(() => formData.hasAutoComplete, () => {
      createData.isMultiline = false;
    });

    onMounted(() => {
      if (createData.variableType === 'array') {
        createData.variableType = 'text';
      }

      // если нет description название берем из переводов
      if (props.isEdit && !createData.description) {
        createData.description = TranslatesList[createData.code];
      }
    });

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      formRef,
      formData,
      createData,
      emitHandler,
      translateText,
    };
  },
});
</script>

<style lang="scss" scoped>
.el-checkbox {
  font-weight: lighter;
}
</style>
