import {
  companiesGeography,
  companyAreasField,
  companyLogoField,
  companyNameField, companyPositionField, companySiteField, companyTelegramField,
  numberOfEmployeesField,
} from 'features/residents/companies/layouts/general';
import { translate } from 'magner';

export const milliardLayout = [
  {
    type: 'column',
    props: {},
    fields: [
      /** Company name */
      companyNameField,
      /** Company turnover (money flow) per year */
      {
        type: 'input',
        name: 'associationTurnoverPerYear',
        dataType: 'number',
        label: translate('hubstr.resident.form.turnover_per_year_dollar.label'),
        props: {
          placeholder: translate('hubstr.resident.form.turnover_per_year.placeholder'),
        },
        validation: [{
          type: 'is-integer',
          trigger: 'blur',
        }],
      },
      /** Number of employees */
      numberOfEmployeesField,
      /** Company logo */
      companyLogoField,
      /** Company areas */
      companyAreasField,
      /** Company position */
      companyPositionField,
      /** Company's geography */
      companiesGeography,
    ],
  },

  /** Company's site, telegram */
  {
    type: 'row',
    props: { justify: 'space-between', elementsGrow: true, align: 'middle' },
    fields: [
      companySiteField,
      companyTelegramField,
    ],
  },
];
