import { routerController } from 'magner';
import { routerConfig } from 'configs/routers/hubstr';
import { etalonRouterConfig } from 'configs/routers/etalon';
import { udschoolRouterConfig } from 'configs/routers/udschool';
import { communityCenterConfig } from 'configs/routers/community_center';
import { cloverRouterConfig } from 'configs/routers/clover';
import { taxi369RouterConfig } from 'configs/routers/taxi369';
import { gazRouterConfig } from 'configs/routers/gaz';
import { pilotsRouterConfig } from 'configs/routers/code-pilots';
import { milliardRouterConfig } from 'configs/routers/milliard';
import { lcRouterConfig } from 'configs/routers/lc';
import { metaHubstrRouterConfig } from 'configs/routers/meta-hubstr';
import { commonRouterConfig } from 'configs/routers/common';
import { dpRouterConfig } from 'configs/routers/dp';
import { setAppTitle } from '~/utils/set-app-title';
import {
  APP_ETALON,
  APP_ID,
  APP_UDSCHOOL,
  APP_COMMUNITY_CENTER,
  ROLE,
  APP_CLOVER,
  APP_TAXI_369,
  APP_GAZPROM,
  APP_CODE_PILOTS,
  APP_MILLIARD,
  META_APP_LC,
  META_APP,
  SUB_DOMEN,
  APP_COMMON,
  COMMON_LIST,
} from '~/constants';

const router = routerController<ROLE>((() => {
  const faviconList = document.querySelectorAll('link[rel="icon"]');
  faviconList.forEach((element) => {
    element.setAttribute('href', `/logo/${APP_ID === APP_COMMON && COMMON_LIST.includes(SUB_DOMEN) 
      ? SUB_DOMEN 
      : APP_ID === APP_COMMON && !COMMON_LIST.includes(SUB_DOMEN) 
        ? 'hubstr' 
        : APP_ID}/favicon.ico`);
  });

  setAppTitle();

  if (APP_ID === APP_COMMON) {
    switch (SUB_DOMEN) {
      case APP_ETALON: {
        return etalonRouterConfig;
      }
      case APP_UDSCHOOL: {
        return udschoolRouterConfig;
      }
      case APP_TAXI_369: {
        return taxi369RouterConfig;
      }
      case APP_CODE_PILOTS: {
        return pilotsRouterConfig;
      }
      case APP_CLOVER: {
        return cloverRouterConfig;
      }
      default: {
        return commonRouterConfig;
      }
    }
  } else {
    switch (APP_ID) {
      case APP_COMMUNITY_CENTER: {
        return communityCenterConfig;
      }
      case APP_GAZPROM: {
        return gazRouterConfig;
      }
      case APP_MILLIARD: {
        return milliardRouterConfig;
      }
      case META_APP_LC: {
        return lcRouterConfig;
      }
      case META_APP: {
        return metaHubstrRouterConfig;
      }
      default: {
        return routerConfig;
      }
    }
  }
})() as any);

export default router;
