// eslint-disable-next-line no-shadow
export enum EnumHeaderButtons {
  telegram = 'Telegram',
  phone = 'Позвонить',
  thanks= 'Поблагодарить',
  add_to_contact = 'В контакты',
  link = 'Ссылка',
  vk = 'VK',
  instagram = 'Instagram',
  facebook = 'Facebook'
}

export const GROUP_TYPES = {
  GROUP: 'group_widget',
  COMMUNITIES: 'group_button_widget',
  SOCIAL: 'group_icons_widget',
  CONTACTS: 'admin_group_contacts_widget',
} as const;

export const ADMIN_GROUP_TYPES = {
  GROUP: 'group',
  COMMUNITIES: 'companies',
  SOCIAL: 'socials',
  CONTACTS: 'contacts',
} as const;

export const TYPE_FIELDS_TO_COMPONENTS = {
  text_widget: 'text-field',
  text_section_widget: 'TextSection',
  list_line_widget: 'ListLine',
  company_list_widget: 'CompanyList',
  info_line_action_widget: 'InfoLineAction',
  list_line_raw_action_widget: 'ListLineRawAction',
  entry_widget: 'Entry',
} as const;

export const TYPE_GROUPS_TO_COMPONENTS = {
  group: 'Group',
  companies: 'GroupButton',
  socials: 'GroupSocials',
  contacts: 'GroupContacts',
} as const;
