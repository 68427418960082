import { action, tablePageController, translate } from 'magner';
import { searchEvents } from 'features/settings/notification/requests/hubstr';
import { reportsRead, downloadReport } from 'features/reports/events/requests';
import EventDownloadLink from 'features/reports/events/eventDownloadLink.vue';
import { eventCategoriesSearch } from 'features/settings/event-categories/requests/hubstr';
import EventLink from 'features/reports/events/eventLink.vue';
import EventTotalRegisteredLink from 'features/reports/events/eventTotalRegisteredLink.vue';
import EventWillNotGoLink from 'features/reports/events/eventWillNotGoLink.vue';
import EventWillGoLink from 'features/reports/events/eventWillGoLink.vue';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import { formatDate, subtractMonthDate } from '~/utils/format-date';

const reportsEventsConfig = tablePageController<any>({
  header: {
    title: translate('hubstr.reports.table.events.title'),
  },
  request: reportsRead,
  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('hubstr.reports.table.not_found'),
    columns: [
      {
        prop: 'name',
        label: translate('hubstr.reports.table.events.name'),
        width: 250,
        fixed: 'left',
        view: {
          type: 'custom',
          component: () => EventLink,
        },
      },
      {
        prop: 'dateStart',
        label: translate('hubstr.reports.table.events.dateStart'),
        width: 160,
        view: {
          type: 'text',
          formatter: (_, row) => (row.dateStart ? formatDate(row.dateStart) : '-'),
        },
      },
      {
        prop: 'type',
        label: translate('hubstr.reports.table.events.type'),
        width: 150,
      },
      {
        prop: 'categories',
        label: translate('hubstr.reports.table.events.categories'),
        width: 250,
        view: {
          type: 'text',
          formatter: (_, row) => row.categories.map((_) => _.name).join(','),
        },
      },
      {
        prop: 'departments',
        label: translate('hubstr.reports.table.events.departments'),
        width: 250,
        view: {
          type: 'text',
          formatter: (_, row) => row.departments.map((_) => _.name).join(','),
        },
      },
      {
        prop: 'totalRegistered',
        label: translate('hubstr.reports.table.events.totalRegistered'),
        width: 165,
        view: {
          type: 'custom',
          component: () => EventTotalRegisteredLink,
        },
      },
      {
        prop: 'eventWillNotGo',
        label: translate('hubstr.reports.table.events.eventWillNotGo'),
        width: 110,
        view: {
          type: 'custom',
          component: () => EventWillNotGoLink,
        },
      },
      {
        prop: 'eventWillGo',
        label: translate('hubstr.reports.table.events.eventWillGo'),
        width: 110,
        view: {
          type: 'custom',
          component: () => EventWillGoLink,
        },
      },
      {
        prop: 'hasPollPrice',
        label: translate('hubstr.reports.table.events.hasPollPrice'),
        width: 110,
        view: {
          type: 'text',
          formatter: (_, row) => (row.hasPollPrice
            ? 'Платное'
            : 'Бесплатное'),
        },
      },
      {
        prop: 'id',
        label: '',
        view: {
          type: 'custom',
          component: () => EventDownloadLink,
        },
      },
    ],
  },
  filters: {
    actions: [{
      type: 'action',
      props: {
        text: translate('hubstr.reports.table.download'),
        type: 'primary',
        class: 'xlsx-download-btn',
        hidden: (data) => !data.state.dateStart?.length || !data.state.events?.length,
      },
      // @ts-ignore
      action: action(async ({ data }) => {
        await downloadReport({ ...data, format: 'xlsx' });
      }),
    }],

    saveToLocalStorage: true,

    submitEvent: 'input',

    filtersInSeparatePanel: true,
    fieldsShowAmount: 4,
    filtersData: {
      department: '',
      type: '',
      categories: '',
      events: '',
      dateStart: subtractMonthDate(),
      dateEnd: '',
    },
    sort: {},

    layout: [
      {
        type: 'select',
        name: 'department',
        options: [],
        label: translate('hubstr.reports.filters.department.label'),
        props: {
          valueKey: 'id',
          labelKey: 'name',
          filterable: true,
          clearable: true,
          remote: true,
          remoteMethod: autocompleteDepartment,
          placeholder: translate('hubstr.reports.filters.department.placeholder'),
          multiple: true,
          collapseTags: true,
        },
      },
      {
        type: 'select',
        name: 'events',
        dataType: 'array',
        options: [],
        label: translate('hubstr.reports.filters.events.label'),
        props: {
          required: true,
          multiple: true,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'name',
          remoteMethod: searchEvents,
          placeholder: translate('hubstr.reports.filters.events.placeholder'),
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
      },
      {
        type: 'datetime',
        name: 'dateStart',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateStart.label'),
        props: {
          required: true,
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateStart.placeholder'),
        },
      },
      {
        type: 'datetime',
        name: 'dateEnd',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateEnd.label'),
        hint: translate('hubstr.reports.filters.dateEnd.hint'),
        props: {
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateEnd.placeholder'),
        },
      },
      {
        type: 'select',
        name: 'type',
        label: translate('hubstr.event.form.type.label'),
        options: [{
          label: translate('hubstr.event.form.type.opt_main'),
          value: 'main',
        }, {
          label: translate('hubstr.event.form.type.opt_additional'),
          value: 'additional',
        }, {
          label: translate('hubstr.event.form.type.opt_open'),
          value: 'open',
        }, {
          label: translate('hubstr.event.form.type.opt_clubInClub'),
          value: 'clubInClub',
        }, {
          label: translate('hubstr.event.form.type.opt_sports'),
          value: 'sports',
        }, {
          label: translate('hubstr.event.form.type.opt_culture'),
          value: 'culture',
        }, {
          label: translate('hubstr.event.form.type.opt_voluntary'),
          value: 'voluntary',
        }, {
          label: translate('hubstr.event.form.type.opt_education'),
          value: 'education',
        }],
        props: {
          placeholder: translate('hubstr.event.form.type.placeholder'),
          clearable: true,
        },
        changeAction: (data) => {
          data.form.events = '';
        },
      },
      {
        type: 'select',
        name: 'categories',
        dataType: 'array',
        label: translate('hubstr.event.form.categories.label'),
        options: [],
        props: {
          multiple: true,
          placeholder: translate('hubstr.event.form.categories.placeholder'),
          filterable: true,
          remote: true,
          valueKey: 'id',
          labelKey: 'name',
          remoteMethod: eventCategoriesSearch,
        },
        changeAction: (data) => {
          data.form.events = '';
        },
      },
    ],
  },
});

export default reportsEventsConfig;
