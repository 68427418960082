import { translate } from 'magner';
import { residentSearch } from 'features/residents/requests/hubstr';
import unpackField from '~/utils/unpack-field';

export interface MetaHubstrField {
  type: string,
  props: Object,
  layout: Object
}
export interface MetaHubstrFieldsGroup {
  title: string;
  fields: MetaHubstrField[];
}

const hubstrConfig = (groups: MetaHubstrFieldsGroup[]) => {
  const layout = {
    type: 'row',
    props: {},
    layout: [
      /** Left column */
      {
        type: 'column',
        props: {
          span: 12,
          xs: 24,
          styles: { 'padding-right': '12px' },
        },
        layout: [
          /** Lastname and Firstname */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [unpackField({
                  type: 'input',
                  required: true,
                  name: 'lastName',
                  label: translate('hubstr.resident.form.last_name.label'),
                  placeholder: translate('hubstr.resident.form.last_name.placeholder'),
                  validation: [{
                    type: 'empty-required',
                    trigger: 'blur',
                  }],
                  // special
                  dataType: 'string',
                })],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [unpackField({
                  type: 'input',
                  required: true,
                  name: 'firstName',
                  label: 'Имя',
                  placeholder: 'Иван',
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'blur',
                  }],
                  // special
                  dataType: 'string',
                })],
              },
            ],
          },
          /** Phone, Email */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'input',
                    name: 'email',
                    label: translate('hubstr.resident.form.email.label'),
                    props: {
                      type: 'email',
                      placeholder: translate('hubstr.resident.form.email.placeholder'),
                    },
                    validation: [
                      {
                        type: 'email-required' as 'email',
                        trigger: 'blur',
                      },
                    ],
                  })],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'input',
                    required: true,
                    name: 'phone',
                    label: 'Номер телефона',
                    placeholder: '+7-123-456-78-90',
                    validation: [{
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    }, {
                      type: 'phone',
                      trigger: 'blur',
                    }],
                    // special
                    dataType: 'string',
                    propsMask: {
                      mask: '+#*',
                    },
                  }),
                ],
              },
            ],
          },
          /** Status */
          unpackField({
            type: 'select',
            required: false,
            name: 'status',
            label: 'Статус',
            placeholder: '',
            propsHiddenFunction: '!data.state.status',
            // special
            remoteMethodSettings: {
              url: '/api/admin/user/statuses',
              parameter: 'statuses',
              type: 'select',
              valueKey: 'code',
              labelKey: 'title',
            },
          }),
          /** Password, role */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'input',
                  required: false,
                  name: 'roleCurrent',
                  label: '',
                  placeholder: '',
                  propsHidden: true,
                  propsDisabled: true,
                },
                {
                  type: 'input',
                  required: false,
                  name: 'rolesList',
                  label: '',
                  placeholder: '',
                  propsHidden: true,
                  propsDisabled: true,
                },
                {
                  type: 'select',
                  required: true,
                  name: 'roles',
                  label: 'Настройка доступов',
                  placeholder: 'Администратор',
                  hint: 'Поле влияет на доступ в административную панель. '
                        + 'Также регулирует некоторые права внутри мобильного приложения. '
                        + 'Например, роль "Администратор" дает право на удаление чужих комментариев',
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                  // special
                  remoteMethodSettings: {
                    url: '/api/admin/role/list',
                    parameter: 'data',
                    valueKey: 'id',
                    labelKey: 'label',
                  },
                }].map((f) => unpackField(f)),
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [
                  /** Password not required */
                  {
                    type: 'input',
                    required: false,
                    name: 'password',
                    label: 'Пароль для панели',
                    placeholder: '',
                    propsHiddenFunction: `!data.state.roles
                      || data.state.rolesList[data.state.roles] !== 'ROLE_ADMIN'
                      || (data.state.rolesList[data.state.roles] === 'ROLE_ADMIN' && data.state.roles !== data.state.roleCurrent.id)`,
                  },
                  /** Password required */
                  {
                    type: 'input',
                    required: true,
                    name: 'password',
                    label: 'Пароль для панели',
                    placeholder: '',
                    propsHiddenFunction: `!data.state.roles
                      || data.state.rolesList[data.state.roles] !== 'ROLE_ADMIN'
                      || (data.state.rolesList[data.state.roles] === 'ROLE_ADMIN' && data.state.roles === data.state.roleCurrent.id)`,
                    validation: [{
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    }],
                  },
                ].map((f) => unpackField(f)),
              },
            ],
          },
          /** Avatar */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              /** Avatar */
              {
                type: 'column',
                props: {
                  span: 8,
                  xs: 24,
                },
                layout: [
                  unpackField({
                    type: 'dropzone',
                    required: false,
                    name: 'avatar',
                    label: 'Аватар',
                    placeholder: '',
                    // special
                    formats: ['jpg', 'png', 'jpeg', 'svg', 'heic'],
                    valueKey: 'id',
                    srcKey: 'thumb_100',
                    removable: true,
                  })],
              },
              /** Entry */
              {
                type: 'column',
                props: {
                  span: 16,
                  xs: 24,
                  styles: { 'padding-left': '12px' },
                },
                layout: [
                  /** Badge */
                  {
                    type: 'textarea',
                    required: false,
                    name: 'userBadge',
                    label: 'Бейдж',
                    placeholder: 'Гендиректор ООО "Атом"',
                    validation: [{
                      type: 'max-length-150' as 'empty',
                      trigger: 'change',
                    }],
                    // special
                    rows: 2,
                  },
                  /** Entry */
                  {
                    type: 'datetime',
                    required: false,
                    name: 'dateOfEntry',
                    label: 'Участник с',
                    placeholder: '15.10.2021',
                    // special
                    dataType: 'date',
                    propsFormat: 'DD.MM.YYYY',
                    propsType: 'date',
                  },
                ].map((f) => unpackField(f)),
              },
            ],
          },
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              /** Recommendation User */
              {
                type: 'column',
                props: {},
                layout: [unpackField({
                  type: 'select',
                  name: 'recommendationUser',
                  label: translate('hubstr.resident.form.recommendation_user.label'),
                  placeholder: translate('hubstr.resident.form.recommendation_user.placeholder'),
                  // special
                  remoteMethodSettings: {
                    url: residentSearch,
                    parameter: 'list',
                    valueKey: 'id',
                    labelKey: 'name',
                    filterable: true,
                    clearable: true,
                  },
                })],
              },
            ],
          },
          /** Forum group */
          {
            type: 'column',
            props: {},
            layout: [unpackField({
              type: 'input',
              required: false,
              name: 'userFormGroup',
              label: translate('hubstr.resident.form.forum_group.label'),
              placeholder: translate('hubstr.resident.form.forum_group.placeholder'),
              // special
              dataType: 'string',
            })],
          },
          /** Role */
          {
            type: 'column',
            props: {},
            layout: [unpackField({
              type: 'select',
              required: false,
              name: 'positionTags',
              label: 'Роль в клубе',
              placeholder: 'Модератор',
              // special
              remoteMethodSettings: {
                url: '/api/admin/user/position/list',
                parameter: 'list',
                valueKey: 'id',
                labelKey: 'name',
                multiple: true,
              },
            })],
          },
          /** Department */
          {
            type: 'column',
            props: {},
            layout: [unpackField({
              type: 'select',
              required: true,
              name: 'department',
              label: 'Отделение',
              placeholder: 'Выбрать',
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
              // special
              remoteMethodSettings: {
                url: '/api/department/autocomplete',
                parameter: 'departments',
                valueKey: 'id',
                labelKey: 'name',
                filterable: true,
              },
            })],
          },
          /** City and birthday */
          {
            type: 'row',
            props: { justify: 'space-between' },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'select',
                    required: false,
                    name: 'userCity',
                    label: 'Город',
                    placeholder: 'Ульяновск',
                    // special
                    remoteMethodSettings: {
                      url: '/api/form/autocomplete',
                      parameter: 'result',
                      type: 'userCity',
                      multiple: true,
                      filterable: true,
                    },
                  }),
                ],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [
                  unpackField({
                    type: 'datetime',
                    required: false,
                    name: 'birthday',
                    label: translate('hubstr.resident.form.birthday.label'),
                    placeholder: translate('hubstr.resident.form.birthday.placeholder'),
                    propsType: 'date',
                    dataType: 'date',
                    propsFormat: 'DD.MM.YYYY',
                    validation: [{
                      type: 'birthday' as 'empty',
                      trigger: 'blur',
                    }],
                  }),
                ],
              },
            ],
          },
          /** Sex */
          unpackField({
            type: 'radio',
            required: false,
            name: 'sex',
            label: 'Пол',
            placeholder: '',
            options: [
              {
                label: 'Мужчина',
                value: true,
              },
              {
                label: 'Женщина',
                value: false,
              },
            ],
          }),
          {
            type: 'column',
            title: 'Контактная информация',
            props: { span: 24, isPaper: true, titleType: 'heading' },
            layout: [
              /** Telegram */
              unpackField({
                type: 'input',
                required: false,
                name: 'telegram',
                label: translate('hubstr.resident.form.telegram.label'),
                placeholder: translate('hubstr.resident.form.telegram.placeholder'),
                dataType: 'string',
              }),
              /** Site */
              unpackField({
                type: 'input',
                required: false,
                name: 'userPersonalWww',
                label: translate('hubstr.resident.form.personal_www.label'),
                placeholder: translate('hubstr.resident.form.personal_www.placeholder'),
                dataType: 'string',
                validation: [{
                  type: 'url' as 'empty',
                  trigger: 'blur',
                }],
              }),
              /** Facebook and Instagram */
              {
                type: 'row',
                props: { elementsGrow: true },
                layout: [
                  {
                    type: 'column',
                    props: {
                      span: 12,
                      xs: 24,
                      styles: { 'padding-right': '6px' },
                    },
                    layout: [
                      unpackField({
                        type: 'input',
                        required: false,
                        name: 'facebook',
                        label: translate('hubstr.resident.form.facebook.label'),
                        placeholder: translate('hubstr.resident.form.facebook.placeholder'),
                        dataType: 'string',
                      }),
                    ],
                  },
                  {
                    type: 'column',
                    props: {
                      span: 12,
                      xs: 24,
                      styles: { 'padding-left': '6px' },
                    },
                    layout: [
                      unpackField({
                        type: 'input',
                        required: false,
                        name: 'instagram',
                        label: translate('hubstr.resident.form.instagram.label'),
                        placeholder: translate('hubstr.resident.form.instagram.placeholder'),
                        dataType: 'string',
                      }),
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      /** Right column */
      {
        type: 'column',
        props: {
          span: 12,
          xs: 24,
          styles: { 'padding-left': '12px' },
        },
        layout: groups.map((group: MetaHubstrFieldsGroup) => ({
          type: 'column',
          title: group.title,
          props: { span: 24, isPaper: true, titleType: 'heading' },
          layout: group.fields.map((field: MetaHubstrField) => unpackField(field)),
        })),
      },
    ],
  };

  return {
    data: {
      actions: [
        {
          type: 'action',
          emits: 'cancel',
          props: {
            text: 'Назад',
          },
        },
        {
          type: 'action',
          emits: 'submit',
          props: {
            text: 'Сохранить',
            type: 'primary',
          },
        },
        {
          type: 'action',
          emits: 'remove',
          props: {
            text: 'Удалить',
            type: 'danger',
          },
        },
      ],
      layout,
    } as any,
  };
};

export default hubstrConfig;
