import { action, tablePageController, translate } from 'magner';
import { downloadReport, reportsRead } from 'features/reports/user-activity/requests';
import { searchResidents } from 'features/settings/notification/requests/hubstr';
import ResidentLink from 'features/reports/user-activity/residentLink.vue';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import EventsRatingCountLink from 'features/reports/user-activity/eventsRatingCountLink.vue';
import { formatDate, subtractMonthDate } from '~/utils/format-date';

/**
 * defaultFirstOption не работает - это обходной путь выборки первого пункта
 */
const departmentsRequest = await autocompleteDepartment('');

const departmentsList = departmentsRequest.data?.rows.sort((a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }

  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }

  return 0;
}) ?? [];

const reportsUserActivityConfig = tablePageController<any>({
  header: {
    title: translate('hubstr.reports.table.user_activity.title'),
  },
  request: reportsRead,
  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('hubstr.reports.table.not_found'),
    columns: [
      {
        prop: 'userId',
        label: translate('hubstr.reports.table.user_activity.userId'),
        width: 150,
        fixed: 'left',
        view: {
          type: 'custom',
          component: () => ResidentLink,
        },
      },
      {
        prop: 'loginCount',
        label: translate('hubstr.reports.table.user_activity.loginCount'),
        width: 120,
      },
      {
        prop: 'lastActive',
        label: translate('hubstr.reports.table.user_activity.lastActive'),
        width: 120,
        view: {
          type: 'text',
          formatter: (_, row) => (row.lastActive ? formatDate(row.lastActive) : '-'),
        },
      },
      {
        prop: 'profilesView',
        label: translate('hubstr.reports.table.user_activity.profilesView'),
        width: 150,
      },
      {
        prop: 'postsView',
        label: translate('hubstr.reports.table.user_activity.postsView'),
        width: 140,
      },
      {
        prop: 'postsPublished',
        label: translate('hubstr.reports.table.user_activity.postsPublished'),
        width: 120,
      },
      {
        prop: 'postsLikes',
        label: translate('hubstr.reports.table.user_activity.postsLikes'),
        width: 100,
      },
      {
        prop: 'postsComments',
        label: translate('hubstr.reports.table.user_activity.postsComments'),
        width: 130,
      },
      {
        prop: 'eventsView',
        label: translate('hubstr.reports.table.user_activity.eventsView'),
        width: 140,
      },
      {
        prop: 'eventsWillGo',
        label: translate('hubstr.reports.table.user_activity.eventsWillGo'),
        width: 130,
      },
      {
        prop: 'eventsRatingCount',
        label: translate('hubstr.reports.table.user_activity.eventsRatingCount'),
        width: 130,
        view: {
          type: 'custom',
          component: () => EventsRatingCountLink,
        },
      },
      {
        prop: 'userLikes',
        label: translate('hubstr.reports.table.user_activity.userLikes'),
        width: 140,
      },
      {
        prop: 'userThanks',
        label: translate('hubstr.reports.table.user_activity.userThanks'),
        width: 145,
      },
    ],
  },
  filters: {
    actions: [
      {
        type: 'action',
        props: {
          text: translate('hubstr.reports.table.download'),
          type: 'primary',
          hidden: (data) => !data.state.dateStart.length || !data.state.department.length,
        },
        // @ts-ignore
        action: action(async ({ data }) => {
          if (data.filters.department.length && data.filters.dateStart.length) {
            await downloadReport({ ...data, format: 'xlsx' });
          }
        }),
      },
    ],

    saveToLocalStorage: false,

    submitEvent: 'input',

    filtersInSeparatePanel: false,
    filtersData: {
      department: departmentsList[0]?.id ? [departmentsList[0]?.id] : [],
      users: '',
      dateStart: subtractMonthDate(),
      dateEnd: '',
    },
    sort: {},

    layout: [
      {
        type: 'select',
        name: 'department',
        options: departmentsList,
        label: translate('hubstr.reports.filters.department.label'),
        props: {
          required: true,
          valueKey: 'id',
          labelKey: 'name',
          placeholder: translate('hubstr.reports.filters.department.placeholder'),
          multiple: true,
          collapseTags: true,
          clearable: true,
          defaultFirstOption: true,
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
        changeAction: (data) => {
          data.form.users = '';
        },
      },
      {
        type: 'select',
        name: 'users',
        dataType: 'array',
        options: [],
        label: translate('hubstr.reports.filters.users.label'),
        props: {
          disabled: (data: { state: {department: string} }) => !data.state.department.length,
          multiple: true,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'name',
          remoteMethod: searchResidents,
          placeholder: translate('hubstr.reports.filters.users.placeholder'),
        },
      },
      {
        type: 'datetime',
        name: 'dateStart',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateStart.label'),
        props: {
          required: true,
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateStart.placeholder'),
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
      },
      {
        type: 'datetime',
        name: 'dateEnd',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateEnd.label'),
        hint: translate('hubstr.reports.filters.dateEnd.hint'),
        props: {
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateEnd.placeholder'),
        },
      },
    ],
  },
});

export default reportsUserActivityConfig;
