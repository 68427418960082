import {
  achievementsField,
  avatarField,
  badgeField, birthdayAdultField, childrenField,
  dateOfEntryField, departmentField,
  emailField, facebookField, familyStatusField,
  firstNameField,
  instagramField,
  lastNameField,
  passwordField, passwordFieldRequired,
  phoneField,
  positionTags,
  sexField, sportsField,
  statusField, telegramField,
} from 'features/residents/layouts/general';
import { translate } from 'magner';
import { UserRoles } from 'features/residents/types/hubstr';
import { autocompleteRole, autocompleteSearch } from 'features/residents/requests/hubstr';
import { clubRoleRead } from 'features/settings/club-roles/requests/hubstr';

export const gazLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      /** Lastname and Firstname */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [lastNameField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [firstNameField],
            }],
          },
        ],
      },
      /** Phone, Email */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [emailField],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [phoneField],
            }],
          },
        ],
      },
      /** Status */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [statusField],
      },
      /** Password, role */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-right': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [{
                type: 'input',
                name: 'roleCurrent',
                props: {
                  disabled: true,
                  hidden: true,
                },
              },
              {
                type: 'input',
                name: 'rolesList',
                props: {
                  disabled: true,
                  hidden: true,
                },
              }, {
                type: 'select',
                name: 'roles',
                label: translate('hubstr.resident.form.roles.label'),
                hint: translate('hubstr.resident.form.roles.hint'),
                options: [],
                props: {
                  required: true,
                  placeholder: translate('hubstr.resident.form.roles.placeholder'),
                  valueKey: 'id',
                  labelKey: 'label',
                  remote: true,
                  remoteMethod: autocompleteRole,
                },
                validation: [{
                  type: 'empty-required' as 'empty',
                  trigger: 'change',
                }],
              }],
            }],
          },
          {
            type: 'column',
            props: {
              span: 12,
              xs: 24,
              styles: { 'padding-left': '6px' },
            },
            layout: [{
              type: 'row',
              props: { justify: 'space-between', elementsGrow: true },
              fields: [passwordField,
                passwordFieldRequired],
            }],
          },
        ],
      },
      /** Avatar */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          /** Avatar */
          {
            type: 'column',
            props: {
              span: 8,
              xs: 24,
            },
            fields: [avatarField],
          },
          /** Entry */
          {
            type: 'column',
            props: {
              span: 16,
              xs: 24,
              styles: { 'padding-left': '12px' },
            },
            layout: [
              badgeField,
              dateOfEntryField,
            ],
          },
        ],
      },
      /** Club position (role) and atlases amount */
      {
        type: 'row',
        props: { justify: 'space-between', elementsGrow: true },
        fields: [positionTags],
      },
      /** Department */
      {
        type: 'row',
        props: { elementsGrow: true },
        fields: [departmentField],
      },

      /** ABOUT ME */
      {
        type: 'column',
        title: translate('hubstr.resident.form.about_me'),
        props: { span: 24, isPaper: true, titleType: 'heading' },
        layout: [
          /** City and Birth date */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [{
                    type: 'select',
                    name: 'userCity',
                    options: [],
                    label: translate('hubstr.resident.form.city.label'),
                    props: {
                      required: true,
                      multiple: true,
                      filterable: true,
                      remote: true,
                      remoteMethod: autocompleteSearch('userCity'),
                      placeholder: translate('hubstr.resident.form.city.placeholder'),
                    },
                    validation: [{
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    }],
                  }],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [birthdayAdultField],
                }],
              },
            ],
          },
          /** Gender identity */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [sexField],
          },
          /** Family status and children */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [familyStatusField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [childrenField],
                }],
              },
            ],
          },
          /** Interests (hobbies) */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [{
              type: 'select',
              name: 'userInterests',
              label: translate('hubstr.resident.form.interests.label'),
              options: [],
              props: {
                multiple: true,
                filterable: true,
                allowCreate: true,
                remote: true,
                remoteMethod: autocompleteSearch('interests'),
                placeholder: translate('hubstr.resident.form.interests.placeholder'),
              },
            }],
          },
          /** Sports */
          {
            type: 'row',
            props: { elementsGrow: true },
            fields: [sportsField],
          },
          /** CONTACTS */
          /** Telegram and VK */
          {
            type: 'row',
            props: { titleType: 'heading', elementsGrow: true },
            title: translate('hubstr.resident.form.contacts.label'),
            fields: [telegramField],
          },
          /** Facebook and Instagram */
          {
            type: 'row',
            props: { elementsGrow: true },
            layout: [
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-right': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [facebookField],
                }],
              },
              {
                type: 'column',
                props: {
                  span: 12,
                  xs: 24,
                  styles: { 'padding-left': '6px' },
                },
                layout: [{
                  type: 'row',
                  props: { justify: 'space-between', elementsGrow: true },
                  fields: [instagramField],
                }],
              },
            ],
          },
        ],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-left': '12px' },
    },
    layout: [
      /** Expertise, Goal (target) for a year, achievements, resources (to provide and receive)  */
      {
        type: 'column',
        title: translate('hubstr.resident.form.useful'),
        props: { titleType: 'heading' },
        fields: [
          achievementsField,
        ],
      },
    ],
  },
];
