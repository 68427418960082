<template>
  <div class="community-block">
    <span
      class="community-block_label"
    >
      {{ translateText('hubstr.resident.form.community_manager') }}:
    </span>
    <el-button
      type="text"
      class="community-block_link"
      @click.prevent="clickButton(modelValue.id)"
    >
      {{ `${modelValue.firstName} ${modelValue.lastName}` }}
    </el-button>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { routeTransform } from '~/utils/route-transform';

export default defineComponent({
  name: 'ResidentLink',
  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
    field: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup () {
    const { customT } = useTranslate();
    // @ts-ignore
    const clickButton = (residentId: string) => {
      window.open(`${routeTransform()}/residents/${residentId}`, '_blank');
    };

    return {
      clickButton,
      translateText: (code: string) => customT(translate(code)),
    };
  },
  methods: { translate },
});
</script>

<style scoped>
.community-block {
  display: flex;
  align-items: center;
}

.community-block_label {
  color: #606266;
  white-space: nowrap;
  padding: 10px 5px 10px 0;
  margin-bottom: 12px;
}

</style>
