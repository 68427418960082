<template>
  <draggable
    :list="buttons"
    :group="buttons.type"
    item-key="name"
    @end="emitData"
  >
    <template #item="{element, index}">
      <div class="button-block">
        <div class="button-block__title">
          <el-icon :size="15" class="button-block__title-icon">
            <DCaret />
          </el-icon>
          {{ EnumHeaderButtons[element.name] || '???' }}
        </div>
        <div class="button-block__check">
          <el-switch
            v-model="element.visible"
            size="small"
            :disabled="countSelected === 4 && !element.visible"
            @change="emitData"
          />
        </div>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, PropType, reactive,
} from 'vue';
import { Header, WidgetHeaderSocial } from 'features/settings/mobile-editor/interfaces';
import { EnumHeaderButtons } from 'features/settings/mobile-editor/constants';
import Draggable from 'vuedraggable-es';
import { DCaret } from '@element-plus/icons-vue';

interface Button {
  type: string,
  name: string,
  link: string | null,
  visible?: boolean,
  entityId?: string,
  actions?: [Object],
}

export default defineComponent({
  name: 'HeaderButtonsSection',
  components: { Draggable, DCaret },
  props: {
    data: {
      type: Object as PropType<Header[]>,
      required: true,
    },
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const state = reactive<Button[]>(props.data.find((i) => i.type === 'header_group_buttons_widget')?.widgets || []);

    const buttonsList = reactive<Button[]>([
      {
        type: 'header_social_button_widget',
        name: 'thanks',
        link: 'data.thanks',
        visible: false,
        entityId: 'data.id',
      },
      {
        type: 'header_social_button_widget',
        name: 'telegram',
        link: 'data.contacts.telegram',
        visible: false,
      },
      {
        type: 'header_social_button_widget',
        name: 'phone',
        link: 'data.contacts.phone',
        visible: false,
      },
      {
        type: 'header_social_button_widget',
        name: 'add_to_contact',
        link: 'data.contacts.phone',
        visible: false,
        actions: [
          {
            type: 'add_to_contacts_action',
            contactsData: {
              badge: 'data.additions.userBadge',
              email: 'data.contacts.email',
              phone: 'data.contacts.phone',
              avatar: 'data.avatar',
              avatarSmall: 'data.avatar_small',
              userId: 'data.id',
              birthday: 'data.birthday',
              lastName: 'data.lastName',
              firstName: 'data.firstName',
              companyName: 'data.firstAssociationName',
            },
          },
        ],
      },
      // {
      //   type: 'header_social_button_widget',
      //   name: 'link',
      //   link: '',
      //   visible: false,
      // },
      {
        type: 'header_social_button_widget',
        name: 'vk',
        link: 'data.contacts.vk',
        visible: false,
      },
      {
        type: 'header_social_button_widget',
        name: 'instagram',
        link: 'data.contacts.instagram',
        visible: false,
      },
    ]);

    /**
     * Добавляет к buttons нехватающих кнопок из buttonsList
     * */
    const filterButton = buttonsList.filter((button: Button) => !state.some((b) => b.name === button.name));

    const buttons = reactive(state.concat(filterButton));

    /**
     * Проходит по пропсу и если кнопка с таким именем найдена в Buttons
     * Включает флаг visible в Buttons
     * */
    onMounted(() => {
      buttons.map((item: Button) => {
        state.map((h: Button) => {
          if (item.name === h.name) {
            item.visible = true;
          }
          return true;
        });
        return true;
      });
    });

    /**
     * Считает кол-во выбранных кнопок
     * */
    const countSelected = computed(() => buttons.filter((i) => i.visible).length);

    /**
     * Выбирает где visible:true, удаляет visible, эмитит
     * */
    const emitData = () => {
      // const out = buttons.filter((i) => i.visible).map(({ type, name, link }) => ({ type, name, link }));
      const out = buttons.filter((i) => i.visible).map(({ visible, ...button }) => button);
      console.log(out);

      emit('update:data', out);
    };

    return {
      EnumHeaderButtons,
      state,
      buttons,
      countSelected,
      emitData,
    };
  },
});
</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--el-box-shadow-lighter);
  padding: 10px 20px;
  border-radius: var(--el-card-border-radius);
  margin-bottom: 10px;

  &__title {
    display: flex;
    align-items: center;
  }
  &__title-icon {
    cursor: move;
    margin-right: 5px;
  }
}
</style>
