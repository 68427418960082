import { PreferenceItem } from 'features/settings/preferences/interfaces';
import { request } from '~/utils/request';

interface GetRreference {
  data: {
    list: Array<PreferenceItem>
  }
}

export const preferenceGet = request.card(async ({ api }) => {
  try {
    const res = await api.get<GetRreference>('/api/admin/setting/list?page=1&count=150');

    if (res.data) {
      const responseList = res.data.data?.list ?? [];
      const defaultValue = { value: null };
      
      return {
        data: {
          preferenceLayout: {
            // eslint-disable-next-line max-len
            sendNotificationResidentNew: responseList.find((i: PreferenceItem) => i.code === 'sendNotificationResidentNew') ?? defaultValue,
            // eslint-disable-next-line max-len
            maxUserDevicesCount: responseList.find((i: PreferenceItem) => i.code === 'maxUserDevicesCount') ?? defaultValue,
            // eslint-disable-next-line max-len
            analyticCollectorEnabled: responseList.find((i: PreferenceItem) => i.code === 'analyticCollectorEnabled') ?? defaultValue,
            // eslint-disable-next-line max-len
            sendWelcomeMessage: responseList.find((i: PreferenceItem) => i.code === 'sendWelcomeMessage') ?? defaultValue,

            postPinEnabled: responseList.find((i: PreferenceItem) => i.code === 'postPinEnabled') ?? defaultValue,
          },
        },
      };
    }
  } catch (e) {
    return {
      error: { message: '', fields: {} },
    };
  }
});

export const preferenceUpdate = request.custom(async ({ api, data, parseError }) => {
  const res = await api.put('/api/admin/setting/multi', { settings: data.data.preferenceLayout });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: 'ok' };
});
