<template>
  <el-row :gutter="30" class="mobile-editor">
    <el-col :lg="16" :md="14" :xs="24">
      <CollapseWrap>
        <template #title>
          {{ translateText('hubstr.editor.main.header.title') }}
        </template>
        <template #default>
          <HeaderModule :data="state" />
        </template>
      </CollapseWrap>
      <CollapseWrap>
        <template #title>
          {{ translateText('hubstr.editor.main.body.title') }}
        </template>
        <template #default>
          <BodyModule :data="state" />
        </template>
      </CollapseWrap>
    </el-col>
    <el-col :lg="8" :md="10" :xs="24">
      <div class="phone-wrap">
        <PhoneMock :data="state" />
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, PropType, reactive,
} from 'vue';
import CollapseWrap from 'features/settings/mobile-editor/components/left-side/components/collapse-wrap.vue';
import HeaderModule from 'features/settings/mobile-editor/components/left-side/modules/header-module.vue';
import { Widgets } from 'features/settings/mobile-editor/interfaces';
import BodyModule from 'features/settings/mobile-editor/components/left-side/modules/body-module.vue';
import PhoneMock from 'features/settings/mobile-editor/components/right-side/phone-mock.vue';
import { onBeforeRouteLeave } from 'vue-router';
import {
  magnerConfirm, magnerMessage, translate, useTranslate, 
} from 'magner';
import { AdditionsList, mobileProfileUpdate } from 'features/settings/mobile-editor/requests';

export default defineComponent({
  name: 'EditMobileProfileLayout',
  components: {
    PhoneMock,
    BodyModule,
    HeaderModule,
    CollapseWrap,
  },
  props: {
    modelValue: {
      type: Object as PropType<Widgets>,
      required: true,
    },
  },
  setup (props) {
    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    const state = reactive(props.modelValue) as Widgets;

    /**
     * Предупреждение при покидании страницы
     * */
    onBeforeRouteLeave((to, from, next) => {
      magnerConfirm({
        message: translateText('hubstr.editor.warnings.save'),
        title: translateText('hubstr.editor.warnings.attention'),
        confirmButtonText: translateText('hubstr.editor.save_exit'),
        cancelButtonText: translateText('hubstr.editor.exit_no_save'),
        type: 'warning',
        closeOnClickModal: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = translateText('hubstr.editor.saving');

            // сохранение
            mobileProfileUpdate({ data: { editMobileProfileLayout: state } } as any)
              .then((res) => {
                // error
                if (res.error) {
                  magnerMessage({
                    type: 'error',
                    message: translateText('hubstr.editor.errors.saving'),
                  });
                  done();
                  return;
                }
                // success
                magnerMessage({
                  type: 'success',
                  message: translateText('hubstr.editor.success.save'),
                });
                done();
                next();
              });
          } else {
            done();
            next();
          }
        },
      });
    });

    return {
      state,
      translateText,
    };
  },
});
</script>

<style lang="scss" scoped>
.mobile-editor {
  width: 100%;
}

.phone-wrap {
  position: fixed;
  right: 40px;
  top: 47% !important;
  transform: translateY(calc(-50% + 24px));
}

@media (max-width: 1300px) {
  .phone-wrap {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .phone-wrap {
    position: relative;
    margin-top: 40px;
    margin-bottom: 60px;
    right: 0;
  }
}
</style>
