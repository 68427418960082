import { IReportsEventsItem } from 'features/reports/events/types';
import { request } from '~/utils/request';
import { roundToTwoSigns } from '~/utils/format-date';
import { API_URL } from '~/constants';

export const reportsRead = request.table<IReportsEventsItem>(async ({ api, data }) => {
  let filters = '';
  if (data.filters.events) {
    filters += data.filters.events.map((u: string) => `events[]=${u}`).join('&');
  }
  if (data.filters.department) {
    filters += `&department=${data.filters.department}`;
  }
  if (data.filters.dateStart) {
    const d = new Date(data.filters.dateStart);
    filters += `&dateStart=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  }
  if (data.filters.dateEnd) {
    const d = new Date(data.filters.dateEnd);
    filters += `&dateEnd=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  } else {
    filters += '&dateEnd=';
  }

  const res = await api.get<{ data: IReportsEventsItem[] }>(
    `/api/admin/analytics/report/events?${filters}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.data,
      pagination: null,
    },
  };
});

export const downloadReport = request.custom(async (
  {
    data,
    lstorage,
  },
) => {
  const token = lstorage.read('token');

  let filters = '';
  if (data.filters.events) {
    filters += data.filters.events.map((u: string) => `events[]=${u}`).join('&');
  }
  if (data.filters.dateStart) {
    const d = new Date(data.filters.dateStart);
    filters += `&dateStart=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  }
  if (data.filters.dateEnd) {
    const d = new Date(data.filters.dateEnd);
    filters += `&dateEnd=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  } else {
    filters += '&dateEnd=';
  }

  // @ts-ignore
  fetch(`${API_URL}/api/admin/analytics/export/events?${filters}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // @ts-ignore
      a.download = 'отчет_мероприятия.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

  return { data: 'ok' };
});
