import { AppInfo, Theme } from 'features/settings/appearance/interfaces';
import { fileUpload } from 'features/common/file-request';
import appInfoRequest from 'configs/development/app-info';
import { request } from '~/utils/request';
import { setAppTitle } from '~/utils/set-app-title';

export const allGet = request.card<any>(async ({ api }) => {
  const [appearance, theme] = await Promise.all([
    await api.get<{ data: AppInfo[]}>('/api/admin/apps/list'),
    await api.get<{ data: { list: Theme[] } }>('/api/admin/setting/list/?page=1&count=100&filters[0][id]=category&filters[0][value]=theme'),
  ]);

  if (appearance?.error || theme?.error) {
    return { error: appearance.error || theme.error };
  }

  return {
    data: {
      appearanceLayout: {
        ...appearance.data.data[0],
        theme: theme.data.data.list[0],
      },
    },
  };
});

export const appearanceNameUpdate = request.custom<{appearanceLayout: AppInfo[]}>(async ({
  api, data, parseError, lstorage, appStore,
}) => {
  let logoId = null;

  if (data.data.appearanceLayout?.newLogo) {
    const res = await fileUpload(
      data.data.appearanceLayout.id,
      (data.data.appearanceLayout.newLogo as File[])[0],
      'app',
      'app_logo',
      'default',
      1,
    );

    if (res?.error) {
      return {
        error: res.error,
      };
    }

    logoId = res.data.id;
  }
  const res = await api.patch<any>(
    '/api/admin/apps/update_partial',
    {
      id: data.data.appearanceLayout.id,
      title: data.data.appearanceLayout.title,
      logo: logoId,
    },
  );

  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  // Обновить данные в локалстораж
  const appInfo = await appInfoRequest('');
  // @ts-ignore
  lstorage.put('title', appInfo.data?.title);
  // @ts-ignore
  lstorage.put('logo', appInfo.data?.defaultLogo);
  appStore.setAppLogo(appInfo.data?.defaultLogo);

  setAppTitle();

  return {
    data: { appearanceLayout: res.data.data },
  };
});

export const themeMenuUpdate = request.custom<any>(async ({ api, data, parseError }) => {
  const res = await api.put<any>(
    '/api/admin/setting',
    {
      code: 'bottomBarType',
      value: data.data.appearanceLayout.theme.value,
    },
  );

  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  return {
    data: 'ok',
  };
});

export const allUpdate = request.custom<any>(async ({ data }) => {
  const [themeMenu, updateName] = await Promise.all([
    await themeMenuUpdate(data),
    await appearanceNameUpdate(data),
  ]);

  if (updateName?.error || themeMenu?.error) {
    return {
      error: updateName?.error || themeMenu?.error,
    };
  }

  return {
    data: 'ok',
  };
});
