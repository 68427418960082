import { reactive } from 'vue';
import { Department } from 'features/residents/types/hubstr';
import { DefaultLogo } from 'features/settings/appearance/interfaces';
import { withState } from '~/store/helper';

type State = {
  departments: Department[],
  defaultLogo: DefaultLogo | null
}
type Mutations = {
  setDepartments: (value: Department[]) => void
  setAppLogo: (value: DefaultLogo) => void
}
type Actions = {
}

// State
const state = reactive<State>({
  departments: [],
  defaultLogo: null,
});

// Mutation
const setDepartments = (value: Department[]) => state.departments = value;
const setAppLogo = (value: DefaultLogo) => state.defaultLogo = value;

// Actions

export default (): State & Mutations & Actions => withState({
  setDepartments,
  setAppLogo,
}, state);
