<template>
  <el-button
    type="text"
    @click="clickButton(row.id)"
  >
    {{ `${row[type]}` }}
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { routeTransform } from '~/utils/route-transform';

export default defineComponent({
  name: 'EventGuestsLink',
  props: {
    type: {
      type: String,
      default: '',
    },
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    // @ts-ignore
    const clickButton = (eventId) => {
      let status = '';
      switch (props.type) {
        case 'totalRegistered': {
          status = 'new';
          break;
        }
        case 'eventWillNotGo': {
          status = 'willNotGo';
          break;
        }
        case 'eventWillGo': {
          status = 'willGo';
          break;
        }
      }
      const lstorage = JSON.parse(localStorage.getItem('magner-hubstr') || '');
      lstorage.filters['event-guests'] = {
        filters: {
          status,
        },
      };
      localStorage.setItem('magner-hubstr', JSON.stringify(lstorage));

      window.open(`${routeTransform()}events/${eventId}/guests`, '_blank');
    };

    return {
      clickButton,
    };
  },
});
</script>
