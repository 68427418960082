import type { Company, CompanyData } from 'features/residents/companies/types/hubstr';

// eslint-disable-next-line no-shadow
export enum UserRoles {
  Admin = 'ROLE_ADMIN',
  Resident = 'ROLE_USER',
  CommunityManager ='ROLE_COMMUNITY_MANAGER',
  Mentor = 'ROLE_MENTOR',
  Moderator = 'ROLE_MODERATOR',
  Proforg = 'ROLE_PROFORG'
}

export type UserStatus = Record<string, string>;

export interface RolesResponse {
  id: string,
  name: string,
  label: string,
}

export interface Tag {
  id: string,
  name: string,
  color: string,
}

export interface Department {
  id: string,
  name: string,
}

export interface Resident {
  id: string,
  fio?: string,
  communityManagerName?: string,
  firstName: string,
  lastName: string,

  status: string,
  roles: UserRoles[],
  acceptRules: null,
  positionTags: string[],
  department: Department | null,

  avatar: File[] | string | null,
  birthday: Date,
  sex: boolean,

  phone: string,
  email: string,
  facebook: string,
  instagram: string,
  telegram: string,

  createdAt: Date,
  dateOfEntry: Date,

  companies: Company[],

  additions: {
    userBusinessStartYear: {
      date: Date,
      timezone_type: number,
      timezone: string
    },
    userAtlas: number,
    userBadge: string,
    userTurnoverPerYear: number,
    userNumberOfEmployees: number,
    userEducation: string,
    userInterests: string,
    userPersonalWww: string,
    userVideoPresentation: string,
    userFormGroup: string,
    userCity: string,
    userYearTarget: string,
    userFactsAboutMe: string,
    userExpertise: string,
    userProvideResources: string,
    userAchievements: string,
    userLookResources: string,
    userRequest: string,
    userIndustries: string,
    userFamilyStatus: string,
    userChildren: string,
    userLookExpertise?: string;
    description?: string,
  }
  communityManager?: {
    id: string,
    firstName: string,
    lastName: string,
  }
  mentor?: {
    id: string,
    firstName: string,
    lastName: string,
  }
  recommendationUser?: {
    id: string,
    firstName: string,
    lastName: string
  }
}

export interface ResidentList {
  id: string,
  fio?: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  positionTags: Tag[]
  birthday: Date,
  dateOfEntry: Date,
  status: UserStatus,
  avatar: {
    big: string,
    id: string,
    thumb_100: string,
  }
  communityManager?: {
    id: string;
    firstName: string,
    lastName: string,
  }
  isCommunityManager?: boolean;
  mentor?: {
    id: string;
    firstName: string,
    lastName: string,
  }
  isMentor?: boolean;
}

export interface ResidentCreate {
  id: number,
  firstName: string,
  lastName: string,
  password: string,

  roles: UserRoles,
  positionTags: string[] | string,
  department: string,

  avatar: File[] | string | null,
  birthday: Date,
  sex: boolean | '',

  phone: string,
  email: string,
  facebook: string,
  instagram: string,
  telegram: string,

  dateOfEntry: Date,

  companies: CompanyData[],
  companiesData: Company[],

  status: UserStatus,

  // Additions
  userBusinessStartYear: {
    date: Date,
    timezone_type: number,
    timezone: string
  },
  userAtlas: number,
  userBadge: string,
  userTurnoverPerYear: number | string,
  userNumberOfEmployees: number | string,
  userEducation: string,
  userInterests: string[],
  userSports: string[],
  userPersonalWww: string,
  userVideoPresentation: string,
  userFormGroup: string,
  userCity: string[],
  userYearTarget: string,
  userFactsAboutMe: string,
  userExpertise: string,
  userProvideResources: string,
  userAchievements: string,
  userLookResources: string,
  userRequest: string,
  userIndustries: string[],
  userFamilyStatus: string,
  userChildren: string,
  description?: string,
}
