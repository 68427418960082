<template>
  <el-card :class="[{'question_with-conditions': data.conditions && data.conditions.length}, 'px-0']">
    <template #header>
      <el-row>
        <el-col :span="12" style="display: flex; padding-top: 5px;">
          <el-button
            style="width: auto;margin: 0 10px 0 0;vertical-align: top;height: 24px"
            :type="state.collapsed ? 'info' : 'primary'"
            :icon="state.collapsed ? View : Hide"
            size="small"
            circle
            @click="toggleCollapsed"
          />
          <h3 style="display: inline-block; vertical-align: top">
            {{ `${translateText('gaz.interview.question.title')} ${data.stepSerial}` }}
            <span style="color: #a8abb2">{{ `- ${currentQuestionTypeText}` }}</span>
            <span 
              v-if="price" 
              style="color: #a8abb2"
            > 
              (Цена: {{ price }} ₽)
            </span>
          </h3>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button-group v-if="!onlyMeta">
            <el-button
              style="width: auto;"
              :disabled="isFirst"
              type="primary"
              :icon="ArrowUpBold"
              plain
              @click="emitMoveUp"
            />
            <el-button
              style="width: auto;"
              :disabled="isLast"
              type="primary"
              :icon="ArrowDownBold"
              plain
              @click="emitMoveDown"
            />
            <el-button
              v-if="canAddConditions"
              style="width: auto;"
              type="info"
              :icon="QuestionFilled"
              plain
              @click="addConditions"
            />
            <el-button
              style="width: auto;"
              type="danger"
              :icon="Delete"
              plain
              @click="emitDelete"
            />
          </el-button-group>
        </el-col>
      </el-row>
    </template>

    <div v-show="!state.collapsed">
      <el-row>
        <el-col>
          <el-input
            v-model="state.question"
            :placeholder="translateText('gaz.interview.question.placeholder_question')"
            :class="[{'is-error': errors.question && showErrors}]"
            @update:modelValue="emitUpdateData"
          >
            <template v-if="errors.question && showErrors" #suffix>
              <el-icon color="#FF0000" size="20">
                <component :is="Warning" />
              </el-icon>
            </template>
          </el-input>
        </el-col>
      </el-row>
      <el-row style="padding-top: 20px;">
        <el-col>
          <el-input
            v-model="state.description"
            :placeholder="translateText('gaz.interview.question.placeholder_description')"
            :class="[{'is-error': errors.description && showErrors}]"
            @update:modelValue="emitUpdateData"
          >
            <template v-if="errors.description && showErrors" #suffix>
              <el-icon color="#FF0000" size="20">
                <component :is="Warning" />
              </el-icon>
            </template>
          </el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <stars-question
            v-if="data.type === QUESTION_TYPE.STARS"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />

          <plain-answer-question
            v-if="data.type === QUESTION_TYPE.PLAIN_ANSWER"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            :price="price"
            @update:data="questionDataUpdated"
          />

          <list-question
            v-if="data.type === QUESTION_TYPE.LIST"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />

          <date-question
            v-if="data.type === QUESTION_TYPE.DATE"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />

          <file-question
            v-if="data.type === QUESTION_TYPE.FILE"
            :key="data.uuid"
            :data="data"
            :show-errors="showErrors"
            :only-meta="onlyMeta"
            @update:data="questionDataUpdated"
          />
        </el-col>
      </el-row>

      <el-row justify="end" class="mb-0">
        <el-col :span="24" style="text-align: right">
          <el-checkbox
            v-model="state.required"
            :label="translateText('gaz.interview.question.label')"
            :disabled="onlyMeta"
            style="margin-right: 0;"
            @update:modelValue="emitUpdateData"
          />

          <el-checkbox
            v-if="data.type === QUESTION_TYPE.PLAIN_ANSWER && data.dataType === 'type_int'"
            v-model="state.notEmptyAnswer"
            label="Обязательно купить для регистрации на мероприятие"
            :disabled="onlyMeta"
            style="margin-left: 24px;"
            @update:modelValue="emitUpdateData"
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script lang="ts">
import {
  ArrowUpBold,
  ArrowDownBold,
  Delete,
  View,
  Hide,
  QuestionFilled,
  Warning,
} from '@element-plus/icons-vue';
import { QUESTION_TYPE, QUESTION_TYPE_OPTIONS } from 'features/settings/interviews/constants';
import {
  computed,
  defineComponent, PropType, reactive, Ref, ref, watch,
} from 'vue';
import {
  IQuestionType, IQuestion, IPrice,
} from 'features/settings/interviews/interfaces';
import StarsQuestion from 'features/settings/interviews/components/question/StarsQuestion.vue';
import PlainAnswerQuestion from 'features/settings/interviews/components/question/PlainAnswerQuestion.vue';
import ListQuestion from 'features/settings/interviews/components/question/ListQuestion.vue';
import DateQuestion from 'features/settings/interviews/components/question/DateQuestion.vue';
import FileQuestion from 'features/settings/interviews/components/question/FileQuestion.vue';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'InterviewQuestion',
  components: {
    PlainAnswerQuestion,
    StarsQuestion,
    ListQuestion,
    DateQuestion,
    FileQuestion,
  },
  props: {
    data: {
      type: Object as PropType<IQuestionType>,
      required: true,
    },
    prices: {
      type: Array as PropType<IPrice[]>,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    canAddConditions: {
      type: Boolean,
      default: false,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
  },
  emits: [
    'update:data',
    'delete',
    'move-up',
    'move-down',
    'add-conditions',
    'errors',
  ],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const errors: {
      question: boolean;
      description: boolean;
    } = reactive({
      question: false,
      description: false,
    });
    //
    const state: IQuestion = reactive({
      uuid: props.data.uuid,
      serial: props.data.serial,
      stepSerial: props.data.stepSerial,
      type: props.data.type,
      question: props.data.question,
      description: props.data.description,
      collapsed: props.data.collapsed,
      required: props.data.required,
      notEmptyAnswer: props.data.notEmptyAnswer || false,
    });

    /**
     * Ловим обновления пропсов
     */
    watch(() => props.data, () => {
      state.uuid = props.data.uuid;
      state.serial = props.data.serial;
      state.stepSerial = props.data.stepSerial;
      state.type = props.data.type;
      state.question = props.data.question;
      state.description = props.data.description;
      state.collapsed = props.data.collapsed;
      state.required = props.data.required;
      state.notEmptyAnswer = props.data.notEmptyAnswer || false;
    }, { deep: true });
    /**
     * текущие заполненные данные в вопросе
     */
    const currentValue: Ref<IQuestionType> = ref(props.data);
    /**
     * Метод валидирует введенные данные
     */
    const validateData = () => {
      errors.question = state.question === '';
      // errors.description = state.description === '';

      emit('errors', errors.question || errors.description);
    };
    /**
     * Метод передает "наверх" изменение общих полей
     */
    const emitUpdateData = () => {
      validateData();

      const emitValue = {
        ...state,
        ...currentValue.value,
      };

      if (!(props.data.type === QUESTION_TYPE.PLAIN_ANSWER && props.data.dataType === 'type_int')) {
        emitValue.notEmptyAnswer = false;
      }

      emit('update:data', emitValue);
    };
    /**
     * Метод обрабатывает изменение данных в компоненте и передает "наверх"
     * @param data
     */
    const questionDataUpdated = (data: { data: IQuestionType, errors: any }) => {
      currentValue.value = data.data;
      emitUpdateData();
    };
    /**
     * Метод передает "наверх" команду на удаление вопроса
     */
    const emitDelete = () => {
      emit('delete');
    };
    /**
     * Метод передает "наверх" команду на перемещение вопроса вверх
     */
    const emitMoveUp = () => {
      emit('move-up');
    };
    /**
     * Метод передает "наверх" команду на перемещение вопроса вниз
     */
    const emitMoveDown = () => {
      emit('move-down');
    };
    /**
     * Метод раскрывает/скрывает вопрос
     */
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
    };
    /**
     * Метод отправляет запрос на добавление условий по отображению текущего вопроса
     */
    const addConditions = () => {
      emit('add-conditions');
    };
    const currentQuestionTypeText = computed(
      () => customT(translate(Object.values(QUESTION_TYPE_OPTIONS).filter((i) => i.id === state.type)[0].name)),
    );

    const price = computed(() => {
      if (props.data.type === QUESTION_TYPE.PLAIN_ANSWER && props.data.dataType === 'type_int') {
        return props.prices.find((item) => item.questionId === props.data.uuid)?.price ?? 0;
      }

      return 0;
    });

    return {
      state,
      errors,

      emitUpdateData,
      questionDataUpdated,
      emitDelete,
      emitMoveUp,
      emitMoveDown,
      toggleCollapsed,
      addConditions,
      translateText: (code: string) => customT(translate(code)),

      QUESTION_TYPE,

      ArrowUpBold,
      ArrowDownBold,
      Delete,
      View,
      Hide,
      QuestionFilled,
      Warning,

      currentQuestionTypeText,
      price,
    };
  },
});
</script>

<style lang="scss" scoped>
.question {
  &_with-conditions {
    background-color: #409eff10;
  }
}
</style>
