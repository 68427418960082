<template>
  <el-row :gutter="10" justify="center">
    <el-col v-for="widget in buttonList" :key="widget" :span="6">
      <div class="action-btn">
        <div class="action-btn__icon">
          <component :is="widget.name" />
        </div>
        <div class="action-btn__text">{{ WIDGET_NAME[widget.name] || widget.name }}</div>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, PropType,
} from 'vue';
import { Header } from 'features/settings/mobile-editor/interfaces';
import { translate, useTranslate } from 'magner';
import Telegram from '../assets/icons/telegram.svg';
import Thanks from '../assets/icons/thanks.svg';
import Add_to_contact from '../assets/icons/add_to_contact.svg';
import Link from '../assets/icons/link.svg';
import Phone from '../assets/icons/phone.svg';
import Vk from '../assets/icons/vk.svg';
import Instagram from '../assets/icons/instagram.svg';

export default defineComponent({
  name: 'ActionButtons',
  components: {
    Phone, Telegram, Thanks, Add_to_contact, Link, Vk, Instagram, 
  },
  props: {
    data: {
      type: Object as PropType<Header[]>,
      default: () => {},
    },
  },
  setup (props) {
    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    const WIDGET_NAME = {
      telegram: 'Telegram',
      thanks: translateText('hubstr.editor.phone_preview.action_btns.thanks'),
      phone: translateText('hubstr.editor.phone_preview.action_btns.call'),
      add_to_contact: translateText('hubstr.editor.phone_preview.action_btns.to_contact'),
      link: translateText('hubstr.editor.phone_preview.action_btns.link'),
      vk: 'VK',
      instagram: 'Instagram',
    } as const;

    const buttonList = computed(() => props.data?.find((widget) => widget.type === 'header_group_buttons_widget')?.widgets);

    return {
      buttonList,
      WIDGET_NAME,
    };
  },
});
</script>

<style scoped>
.action-btn {
  display: flex !important;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.action-btn__icon {
  height: 50px;
  width: 50px;
  background-color: #007AFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-btn__text {
  font-size: 10px;
  color: #007AFF;
}
</style>
