<template>
  <el-button
    type="text"
    @click="clickButton(row.id)"
  >
    {{ `${row.name}` }}
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { routeTransform } from '~/utils/route-transform';

export default defineComponent({
  name: 'EventLink',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup () {
    // @ts-ignore
    const clickButton = (eventId) => {
      window.open(`${routeTransform()}events/${eventId}`, '_blank');
    };

    return {
      clickButton,
    };
  },
});
</script>
