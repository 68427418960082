import {
  autocompleteDepartment, autocompleteRole,
  autocompleteSearch,
  residentSearch,
  residentStatusList,
} from 'features/residents/requests/hubstr';
import { RolesResponse, UserRoles } from 'features/residents/types/hubstr';
import { clubRoleRead } from 'features/settings/club-roles/requests/hubstr';
import { translate } from 'magner';
import CommunityManagerLink from 'features/residents/resident/components/communityManagerLink.vue';

/**
 * Фамилия резидента
 */
export const lastNameField = {
  type: 'input',
  name: 'lastName',
  label: translate('hubstr.resident.form.last_name.label'),
  dataType: 'string',
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.last_name.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Имя резидента
 */
export const firstNameField = {
  type: 'input',
  name: 'firstName',
  label: translate('hubstr.resident.form.first_name.label'),
  dataType: 'string',
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.first_name.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * E-mail резидента
 */
export const emailField = {
  type: 'input',
  name: 'email',
  label: translate('hubstr.resident.form.email.label'),
  props: {
    type: 'email',
    placeholder: translate('hubstr.resident.form.email.placeholder'),
  },
  validation: [
    {
      type: 'email-required' as 'email',
      trigger: 'blur',
    },
  ],
};
/**
 * Телефон резидента
 */
export const phoneField = {
  type: 'input',
  name: 'phone',
  label: translate('hubstr.resident.form.phone.label'),
  dataType: 'string',
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.phone.placeholder'),
    mask: {
      mask: '+#*',
    },
  },
  validation: [
    {
      type: 'empty-required' as 'empty',
      trigger: 'blur',
    },
    {
      type: 'phone',
      trigger: 'blur',
    },
  ],
};
/**
 * Статус резидента
 */
export const statusField = {
  type: 'select',
  name: 'status',
  label: translate('hubstr.resident.form.status.label'),
  options: [],
  props: {
    hidden: (data: { state: {status: boolean} }) => !data.state.status,
    remote: true,
    remoteMethod: residentStatusList,
    valueKey: 'code',
    labelKey: 'title',
  },
};
/**
 * Роль резидента
 */
export const roleField = {
  type: 'select',
  name: 'roles',
  label: translate('hubstr.resident.form.roles.label'),
  hint: translate('hubstr.resident.form.roles.hint'),
  options: [],
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.roles.placeholder'),
    valueKey: 'id',
    labelKey: 'label',
    remote: true,
    remoteMethod: autocompleteRole,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Пароль для админ-панели
 */
export const passwordField = {
  type: 'input',
  name: 'password',
  label: translate('hubstr.resident.form.password.label'),
  props: {
    hidden: (data: { state: {roles: string, roleCurrent: RolesResponse, rolesList: Record<string, string>} }) => !data.state.roles
      || data.state.rolesList[data.state.roles] !== UserRoles.Admin
      || (data.state.rolesList[data.state.roles] === UserRoles.Admin && data.state.roles !== data.state.roleCurrent.id),
    placeholder: translate('hubstr.resident.form.password.placeholder'),
  },
};
export const passwordFieldRequired = {
  type: 'input',
  name: 'password',
  label: translate('hubstr.resident.form.password.label'),
  props: {
    required: true,
    hidden: (data: { state: {roles: string, roleCurrent: RolesResponse, rolesList: Record<string, string>} }) => !data.state.roles
      || data.state.rolesList[data.state.roles] !== UserRoles.Admin
      || (data.state.rolesList[data.state.roles] === UserRoles.Admin && data.state.roles === data.state.roleCurrent.id),
    placeholder: translate('hubstr.resident.form.password.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Комьюнити менеджер
 */
export const communityManager = {
  type: 'custom',
  name: 'communityManager',
  component: () => CommunityManagerLink,
  props: {
    hidden: (data: { state: {communityManager: any} }) => !data.state.communityManager,
  },
};
/**
 * Аватар резидента
 */
export const avatarField = {
  type: 'dropzone',
  name: 'avatar',
  label: translate('hubstr.resident.form.avatar.label'),
  props: {
    formats: ['jpg', 'png', 'jpeg', 'svg', 'heic'],
    valueKey: 'id',
    srcKey: 'thumb_100',
    removable: true,
  },
};
/**
 * Бейдж
 */
export const badgeField = {
  type: 'row',
  props: { elementsGrow: true },
  fields: [
    {
      type: 'textarea',
      name: 'userBadge',
      label: translate('hubstr.resident.form.badge.label'),
      props: {
        placeholder: translate('hubstr.resident.form.badge.placeholder'),
        rows: 2,
      },
      validation: [{
        type: 'max-length-150' as 'empty',
        trigger: 'change',
      }],
    },
  ],
};
/**
 * Участник с
 */
export const dateOfEntryField = {
  type: 'row',
  props: { justify: 'space-between', elementsGrow: true },
  fields: [
    {
      type: 'datetime',
      name: 'dateOfEntry',
      dataType: 'date',
      label: translate('hubstr.resident.form.entry.label'),
      props: {
        placeholder: translate('hubstr.resident.form.entry.placeholder'),
        format: 'DD.MM.YYYY',
        type: 'date',
      },
    },
  ],
};
/**
 *
 */
export const recommendationUserField = {
  type: 'select',
  name: 'recommendationUser',
  options: [],
  label: translate('hubstr.resident.form.recommendation_user.label'),
  props: {
    placeholder: translate('hubstr.resident.form.recommendation_user.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    clearable: true,
    remoteMethod: residentSearch,
  },
};
/**
 * Форум-группа
 */
export const forumGroup = {
  type: 'input',
  name: 'userFormGroup',
  label: translate('hubstr.resident.form.forum_group.label'),
  props: {
    placeholder: translate('hubstr.resident.form.forum_group.placeholder'),
  },
};
/**
 * Роль в клубе
 */
export const positionTags = {
  type: 'select',
  name: 'positionTags',
  dataType: 'array',
  options: [],
  label: translate('hubstr.resident.form.position_tag.label'),
  props: {
    placeholder: translate('hubstr.resident.form.position_tag.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: clubRoleRead,
  },
};
/**
 * Отделение
 */
export const departmentField = {
  type: 'select',
  name: 'department',
  options: [],
  label: translate('hubstr.resident.form.department.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.department.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    remoteMethod: autocompleteDepartment,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Отделение для Code Pilots
 */
export const departmentCodePilotsField = {
  type: 'select',
  name: 'department',
  options: [],
  label: translate('hubstr.resident.form.department.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.resident.form.department.placeholder'),
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('department'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};
/**
 * Город
 */
export const cityField = {
  type: 'select',
  name: 'userCity',
  options: [],
  label: translate('hubstr.resident.form.city.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('city'),
    placeholder: translate('hubstr.resident.form.city.placeholder'),
  },
};
/**
 * Дата рождения
 */
export const birthdayField = {
  type: 'datetime',
  dataType: 'date',
  name: 'birthday',
  label: translate('hubstr.resident.form.birthday.label'),
  props: {
    type: 'date',
    placeholder: translate('hubstr.resident.form.birthday.placeholder'),
    format: 'DD.MM.YYYY',
  },
  validation: [{
    type: 'birthday' as 'empty',
    trigger: 'blur',
  }],
};

/**
 * Дата рождения совершеннолетний
 */
export const birthdayAdultField = {
  type: 'datetime',
  dataType: 'date',
  name: 'birthday',
  label: translate('hubstr.resident.form.birthday.label'),
  props: {
    type: 'date',
    placeholder: translate('hubstr.resident.form.birthday.placeholder'),
    format: 'DD.MM.YYYY',
  },
  validation: [{
    type: 'birthday-adult' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Пол
 */
export const sexField = {
  type: 'radio',
  name: 'sex',
  options: [
    {
      label: translate('hubstr.resident.form.gender.option_m'),
      value: true,
    },
    {
      label: translate('hubstr.resident.form.gender.option_f'),
      value: false,
    },
  ],
  label: translate('hubstr.resident.form.gender.label'),
  props: {},
};
/**
 * Образование
 */
export const educationField = {
  type: 'input',
  name: 'userEducation',
  label: translate('hubstr.resident.form.education.label'),
  props: {
    placeholder: translate('hubstr.resident.form.education.placeholder'),
  },
};
/**
 * Семейный статус
 */
export const familyStatusField = {
  type: 'input',
  name: 'userFamilyStatus',
  label: translate('hubstr.resident.form.family_status.label'),
  props: {
    placeholder: translate('hubstr.resident.form.family_status.placeholder'),
  },
};
/**
 * Дети
 */
export const childrenField = {
  type: 'input',
  name: 'userChildren',
  label: translate('hubstr.resident.form.children.label'),
  props: {
    placeholder: translate('hubstr.resident.form.children.placeholder'),
  },
};
/**
 * факты о себе
 */
export const factsAboutMeField = {
  type: 'textarea',
  name: 'userFactsAboutMe',
  label: translate('hubstr.resident.form.facts_about_me.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.facts_about_me.placeholder'),
  },
};
/**
 * Чем могу быть полезен
 */
export const provideResourcesField = {
  type: 'textarea',
  name: 'userProvideResources',
  label: translate('hubstr.resident.form.provide_resources.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.provide_resources.placeholder'),
  },
};
/**
 * В чем я нуждаюсь в настоящий момент
 */
export const lookResourcesField = {
  type: 'textarea',
  name: 'userLookResources',
  label: translate('hubstr.resident.form.look_resources.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.look_resources.placeholder'),
  },
};
/**
 * Интересы
 */
export const interestsField = {
  type: 'select',
  name: 'userInterests',
  label: translate('hubstr.resident.form.interests.label'),
  options: [],
  props: {
    multiple: true,
    filterable: true,
    allowCreate: true,
    remote: true,
    remoteMethod: autocompleteSearch('interests'),
    placeholder: translate('hubstr.resident.form.interests.placeholder'),
  },
};
/**
 * Виды спорта
 */
export const sportsField = {
  type: 'select',
  name: 'userSports',
  label: translate('hubstr.resident.form.sports.label'),
  options: [],
  props: {
    multiple: true,
    filterable: true,
    allowCreate: true,
    remote: true,
    remoteMethod: autocompleteSearch('userSports'),
    placeholder: translate('hubstr.resident.form.sports.placeholder'),
  },
};
/**
 * Виды перерабатываемых отходов
 */
export const wasteTypesField = {
  type: 'select',
  name: 'userWasteTypes',
  label: translate('hubstr.resident.form.waste_types.label'),
  options: [],
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('wasteTypes'),
    placeholder: translate('hubstr.resident.form.waste_types.placeholder'),
  },
};
/**
 * Персональный сайт
 */
export const personalWwwField = {
  type: 'input',
  name: 'userPersonalWww',
  label: translate('hubstr.resident.form.personal_www.label'),
  props: {
    placeholder: translate('hubstr.resident.form.personal_www.placeholder'),
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Ссылка на видео-презентацию
 */
export const videoPresentationField = {
  type: 'input',
  name: 'userVideoPresentation',
  label: translate('hubstr.resident.form.video_presentation.label'),
  props: {
    placeholder: translate('hubstr.resident.form.video_presentation.placeholder'),
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'blur',
  }],
};
/**
 * Telegram
 */
export const telegramField = {
  type: 'input',
  name: 'telegram',
  label: translate('hubstr.resident.form.telegram.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.telegram.placeholder'),
  },
};
/**
 * Telegram ID
 */
export const telegramIdField = {
  type: 'input',
  name: 'externalId',
  label: translate('hubstr.resident.form.telegramId.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.telegramId.placeholder'),
  },
};
/**
 * Facebook
 */
export const facebookField = {
  type: 'input',
  name: 'facebook',
  label: translate('hubstr.resident.form.facebook.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.facebook.placeholder'),
  },
};
/**
 * Instagram
 */
export const instagramField = {
  type: 'input',
  name: 'instagram',
  label: translate('hubstr.resident.form.instagram.label'),
  dataType: 'string',
  props: {
    placeholder: translate('hubstr.resident.form.instagram.placeholder'),
  },
};
/**
 * Экспертиза
 */
export const expertiseField = {
  type: 'textarea',
  name: 'userExpertise',
  label: translate('hubstr.resident.form.expertise.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.expertise.placeholder'),
  },
};
/**
 * Достижения
 */
export const achievementsField = {
  type: 'textarea',
  name: 'userAchievements',
  label: translate('hubstr.resident.form.achievements.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.achievements.placeholder'),
  },
};
/**
 * Стаж
 */
export const businessStartYearField = {
  type: 'datetime',
  name: 'userBusinessStartYear',
  label: translate('hubstr.resident.form.business_start_year.label'),
  dataType: 'date',
  props: {
    type: 'date',
    format: 'DD.MM.YYYY',
    placeholder: translate('hubstr.resident.form.business_start_year.placeholder'),
  },
};
/**
 * Оборот в год, рубли
 */
export const turnoverPerYearField = {
  type: 'input',
  name: 'userTurnoverPerYear',
  dataType: 'number',
  label: translate('hubstr.resident.form.turnover_per_year.label'),
  props: {
    placeholder: translate('hubstr.resident.form.turnover_per_year.placeholder'),
  },
};
/**
 * Кол-во сотрудников
 */
export const numberOfEmployeesField = {
  type: 'input',
  name: 'userNumberOfEmployees',
  dataType: 'number',
  label: translate('hubstr.resident.form.number_of_employees.label'),
  props: {
    placeholder: translate('hubstr.resident.form.number_of_employees.placeholder'),
  },
};
/**
 * Отрасли бизнеса
 */
export const industriesField = {
  type: 'select',
  name: 'userIndustries',
  options: [],
  label: translate('hubstr.resident.form.business_sectors.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('industries'),
    placeholder: translate('hubstr.resident.form.business_sectors.placeholder'),
  },
};
/**
 * Цель на год
 */
export const userYearTarget = {
  type: 'textarea',
  name: 'userYearTarget',
  label: translate('hubstr.resident.form.userYearTarget.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.userYearTarget.placeholder'),
  },
};
/**
 * Какие ищу ресурсы?
 */
export const userLookResources = {
  type: 'textarea',
  name: 'userLookResources',
  label: translate('hubstr.resident.form.userLookResources.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.userLookResources.placeholder'),
  },
};
/**
 * Отрасли бизнеса
 */
export const userLookExpertise = {
  type: 'select',
  name: 'userLookExpertise',
  options: [],
  label: translate('hubstr.resident.form.userLookExpertise.label'),
  props: {
    multiple: true,
    filterable: true,
    remote: true,
    remoteMethod: autocompleteSearch('expertise'),
    placeholder: translate('hubstr.resident.form.userLookExpertise.placeholder'),
  },
};
/**
 *
 */
export const userRequest = {
  type: 'textarea',
  name: 'userRequest',
  label: translate('hubstr.resident.form.userRequest.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.resident.form.userRequest.placeholder'),
  },
};
