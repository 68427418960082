import { User } from 'features/login/requests';
import { request } from '~/utils/request';
import { APP_ID, META_APP } from '~/constants';

const profileRequest = request.profile(async ({
  api, parseError, lstorage, router, 
}) => {
  const res = await api.get<{ result: User, token: string }>('/api/admin/profile');
  if (res.error) {
    // Авторизация по токену в query параметрах
    if (APP_ID === META_APP) {
      setTimeout(() => {
        const token = router.currentRoute.value.query?.token;
        if (token) {
          lstorage.put('token', token);
          window.location.reload();
        }
      });
    }

    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);
    return { error: parseError(res.error) };
  }

  return {
    data: {
      user: res.data?.result,
      role: res.data?.result.roles?.[0],
    },
  };
});

export default profileRequest;
