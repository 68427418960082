<template>
  <el-button
    v-if="row.target?.creator?.id"
    type="text"
    @click="clickButton(row.target?.creator?.id)"
  >
    {{ `${row.target?.creator?.name}` }}
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { ComplaintsList } from 'features/settings/complaints/types/hubstr';
import { routeTransform } from '~/utils/route-transform';

export default defineComponent({
  name: 'ResidentLink',
  props: {
    row: {
      type: Object as PropType<ComplaintsList>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup () {
    // @ts-ignore
    const clickButton = (residentId) => {
      window.open(`${routeTransform()}residents/${residentId}`, '_blank');
    };

    return {
      clickButton,
    };
  },
});
</script>
