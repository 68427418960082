<template>
  <el-button
    v-if="row.target.type === 'user'"
    type="text"
    @click="clickButton(row.target.id)"
  >
    {{ `${row.target.title}` }}
  </el-button>
  <p v-else>
    {{ row.target.title }}
  </p>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { routeTransform } from '~/utils/route-transform';

export default defineComponent({
  name: 'ResidentLink',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup () {
    // @ts-ignore
    const clickButton = (residentId) => {
      window.open(`${routeTransform()}residents/${residentId}`, '_blank');
    };

    return {
      clickButton,
    };
  },
});
</script>
