import {
  Resident, ResidentCreate, Tag, UserRoles, UserStatus,
} from 'features/residents/types/hubstr';
import { fileUpload } from 'features/common/file-request';
import { autocompleteRole, residentUpdateStatus } from 'features/residents/requests/hubstr';
import hubstrConfig from 'features/residents/layouts/meta-hubstr';
import { v4 as uuidv4 } from 'uuid';
import { request } from '~/utils/request';
import { getRolesIdName } from '~/utils/get-roles';

/**
 * Метод получения layout
 * */
export const residentGetLayout = request.custom(async ({ api }) => {
  const res = await api.get('/api/admin/admin-view?code=user_profile');

  if (res.error) {
    return {
      error: res.error,
    };
  }

  const columns = res.data?.data.json;

  return {
    ...hubstrConfig(columns),
  };
});

/**
 *
 */
export const residentGet = request.card<Resident, ResidentCreate>(async ({ api, data, parseError }) => {
  const rolesList = await getRolesIdName();
  const res = await api.get<{ user: Resident }>(`/api/admin/user/read?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  /**
   * Нам нужен список всех additional_fields, так как если было создано новое поле - оно не приходит с бэка
   * пока мы его хотя бы раз не отправим
   */
  const resAdditionalFields = await api.get<any>('/api/admin/additional_fields/list?entityType=user');
  const additionalFields: Record<string, any> = res.data?.user.additions ? { ...res.data.user.additions } : {};

  resAdditionalFields.data.data.map((item: {code: string}) => {
    if (!Object.keys(additionalFields).includes(item.code)) {
      additionalFields[item.code] = '';
    }
    return item;
  });

  return {
    data: {
      password: '',
      ...res.data?.user,
      roleCurrent: res.data?.user.roles?.[0],
      status: (res.data?.user.status as unknown as UserStatus).code,
      birthday: res.data?.user.birthday ? new Date(res.data?.user.birthday) as any : null,
      dateOfEntry: res.data?.user.dateOfEntry ? new Date(res.data?.user.dateOfEntry) as any : null,
      roles: res.data?.user?.roles?.[0].id as any,
      ...additionalFields,
      userCity: res.data?.user.additions.userCity?.split(', ') || null,
      userInterests: res.data?.user.additions.userInterests || [],
      userIndustries: res.data?.user.additions.userIndustries || [],
      userLookExpertise: res.data?.user.additions.userLookExpertise || [],
      positionTags: res.data?.user.positionTags?.map((tag) => (tag as unknown as Tag).id) as string[],
      recommendationUser: res.data?.user.recommendationUser?.id ?? null,
      description: res.data?.user.additions.description ?? '',
      videoPresentation: res.data?.user.additions.video_presentation ?? '',
      rolesList,
      phone: res.data?.user.phone && `+${res.data?.user.phone}`,
    },
  };
});

export const residentCreate = request.card<Resident, ResidentCreate>(async ({
  api, parseError, data, router,
}) => {
  const id = uuidv4();
  const body: any = { id, additions: {} };

  /**
   * Список вложенных ключей (вложенных в additions) нам так же надо получить с бэка
   */
  const resAdditionalFields = await api.get<any>('/api/admin/additional_fields/list?entityType=user');
  const createNestedKeys = resAdditionalFields.data.data.map((i: {code: string}) => i.code);
  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.userCity) body.additions.userCity = data.data.userCity.join(', ');
  if (data.data.userInterests) body.additions.interests = data.data.userInterests;
  if (data.data.userIndustries) body.additions.userIndustries = data.data.userIndustries;
  if (data.data.userAchievements) body.additions.userAchievements = data.data.userAchievements;
  if (data.data.positionTags === '') body.positionTags = [];
  if (typeof data.data.sex === 'string') body.sex = null;
  if (!data.data.department) body.department = null;
  if (!data.data.recommendationUser || data.data.recommendationUser === '') body.recommendationUser = null;

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.post<{ user: Resident }>('/api/admin/user/create', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident', params: { id: res.data?.user.id } });
  return { data: res.data?.user };
});

/**
 *
 */
export const residentUpdate = request.card<Resident, ResidentCreate>(async ({ api, parseError, data }) => {
  const body: any = { id: data.id, additions: {} };

  /**
   * Список вложенных ключей (вложенных в additions) нам так же надо получить с бэка
   */
  const resAdditionalFields = await api.get<any>('/api/admin/additional_fields/list?entityType=user');
  const createNestedKeys = resAdditionalFields.data.data.map((i: {code: string}) => i.code);
  Object.entries(data.data).forEach(([key, val]) => {
    if (createNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });
  if (data.data.dateOfEntry) {
    const entry = new Date(data.data.dateOfEntry);
    body.dateOfEntry = new Date(entry.getTime() - entry.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.birthday) {
    const bd = new Date(data.data.birthday);
    body.birthday = new Date(bd.getTime() - bd.getTimezoneOffset() * 60 * 1000);
  }
  if (data.data.roles) body.roles = [data.data.roles] as unknown as ResidentCreate['roles'];
  if (data.data.userCity) body.additions.userCity = data.data.userCity.join(', ');
  if (data.data.userInterests) body.additions.userInterests = data.data.userInterests;
  if (data.data.userIndustries) body.additions.userIndustries = data.data.userIndustries;
  if (data.data.userAchievements) body.additions.userAchievements = data.data.userAchievements;
  if (data.data.positionTags === '') body.positionTags = [];
  if (data.data.userNumberOfEmployees === '') body.additions.userNumberOfEmployees = null;
  if (data.data.userTurnoverPerYear === '') body.additions.userTurnoverPerYear = null;
  if (typeof data.data.sex === 'string') body.sex = null;
  if (!data.data.department) body.department = null;
  if (data.data.recommendationUser === '') body.recommendationUser = null;

  if (data.data.avatar) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.avatar as File[])[0],
      'user',
      'user_avatar',
      'userAvatar',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { avatar: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.avatar = fileUploadRes.data?.id;
  } else {
    body.avatar = null;
  }

  const res = await api.patch<{ user: Resident }>('/api/admin/user/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  // Если обновился статус
  if (data.data.status) {
    await residentUpdateStatus(data);
  }

  return { data: res.data?.user };
});
