import { AdditionalField, ViewRoot, Widgets } from 'features/settings/mobile-editor/interfaces';
import { defaultProfile } from 'features/settings/mobile-editor/default-profile';
import parseForAdmin from 'features/settings/mobile-editor/utils/parse-for-admin';
import parseForMobile from 'features/settings/mobile-editor/utils/parse-for-mobile';
import { GROUP_TYPES } from 'features/settings/mobile-editor/constants';
import { request } from '~/utils/request';

export let TranslatesList: Record<string, string> = {};
export let AdditionsList: AdditionalField[] = [];

/**
 * Получение переводов
 * */
export const translatesGet = request.custom<void>(async ({ api }) => {
  try {
    const res = await api.get<any>('/api/admin/translations/list?locale=ru&domain=profile_view');
    TranslatesList = res.data.data;
  } catch (e) {
    return { error: e };
  }
});

/**
 * Получение всех существующих additional полей
 * */
export const additionsGet = request.custom<any>(async ({ api }) => {
  try {
    const res = await api.get<any>('/api/admin/additional_fields/list?entityType=user');
    AdditionsList = res.data?.data;

    return { data: res?.data.data };
  } catch (e) {
    return { error: e };
  }
});

/**
 * Создание нового дефолтного view(мп)
 * */
const defaultProfileCreate = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post<{ data: ViewRoot }>(
      '/api/admin/view',
      { ...data },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Запрашивает view(мп) с бэка
 * */
const viewGet = request.custom<ViewRoot>(async ({ api, data }) => {
  try {
    const res = await api.get<{ data: ViewRoot }>(
      '/api/admin/view?code=user_profile',
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Запрашивает view(мп) с бэка. Если view отсуствует, дергает метод создания дефолтного view.
 * */
export const mobileProfileGet = request.card<any>(async ({ api, data, parseError }) => {
  const [view, translate, additions] = await Promise.all([
    // Получаем view
    await viewGet(''),
    // Получаем переводы
    await translatesGet(''),
    // Получаем доп. поля
    await additionsGet(''),
  ]);

  if (view?.error || translate?.error || additions?.error) {
    return { error: 'error' };
  }

  if (!view?.data?.id) {
    const resCreate = await defaultProfileCreate(defaultProfile);

    return {
      data: {
        editMobileProfileLayout: {
          id: resCreate.data.id,
          ...resCreate.data?.widgets,
        },
      },
    };
  }

  return {
    data: {
      editMobileProfileLayout: {
        id: view.data.id,
        ...view.data.widgets,
        data: {
          id: 'data.id',
          name: 'data.name',
        },
      },
    },
  };
});

/**
 * Обновление additional поля
 */
export const updateAdditionalField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.patch<any>(
      '/api/admin/additional_fields/update',
      { ...data },
    );
    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение view(мп)
 * */
const saveView = request.custom(async ({ api, data }) => {
  try {
    const res = await api.put(
      '/api/admin/view',
      {
        id: data.id,
        code: 'user_profile',
        title: 'Профиль пользователя',
        widgets: {
          ...data,
          // ...defaultProfile.widgets,
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение admin-view
 * */
const saveAdminView = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post(
      '/api/admin/admin-view',
      {
        code: 'user_profile',
        json: {
          ...parseForAdmin(data, AdditionsList),
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение представления для редактирования в мп
 * */
const saveMobileEdit = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post(
      '/api/admin/admin-view',
      {
        code: 'mp_profile_form',
        json: {
          ...parseForMobile(data, AdditionsList),
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Обновление поля userBadge.
 * Тк он находится отдельно (в header)
 * */
const saveBadge = async (data: Widgets) => {
  const badgeLabel = data.header.find((i) => i.type === 'header_badge_widget')?.label;
  const badgeDisc = AdditionsList.find((i) => i.code === 'userBadge')?.description;

  if (badgeLabel && badgeLabel !== badgeDisc) {
    try {
      return await updateAdditionalField({
        ...AdditionsList.find((i) => i.code === 'userBadge'),
        description: badgeLabel,
      });
    } catch (err) {
      return { error: err };
    }
  }

  return { data: 'ok' };
};

/**
 * Обновление view(мп) и сохранение представления для админки
 * */
export const mobileProfileUpdate = request.card(async ({ api, data, parseError }) => {
  const deepCopyPayload = JSON.parse(JSON.stringify(data.data.editMobileProfileLayout));

  deepCopyPayload.body = deepCopyPayload.body.map((i: {type: string}) => ({
    ...i,
    type: i.type === 'group' ? GROUP_TYPES.GROUP : i.type,
  }));
    
  const res = await Promise.all([
    // Сохраняем view
    await saveView(deepCopyPayload),
    // Сохраняем admin-view
    await saveAdminView(deepCopyPayload),
    // Сохраняем редактирование в мобилке
    await saveMobileEdit(deepCopyPayload),
    // Отдельно обновляем Badge в Additional листе
    await saveBadge(deepCopyPayload),

  ]).then(([view, admin, edit, badge]) => ({
    view, admin, edit, badge, 
  }));

  if (res.view.error || res.admin.error || res.edit.error || res.badge.error) {
    return { error: 'Ошибка' };
  }
    
  return { data: 'ok' };
});

/**
 * Создание нового additional поля
 * */
export const createNewField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/additional_fields/create',
      { ...data },
    );

    // Обновление списока всех доп.полей
    const additionalFields = await additionsGet('');
    if (additionalFields.error) {
      return { error: additionalFields.error };
    }

    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Добавление поля в автокомплит
 * */
export const createAutocompleteField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/autocomplete/values/create',
      { ...data },
    );

    return { data: res };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Удаление поля из списка автокомплитов
 * */
export const removeAutocompleteField = request.custom(async ({ api, data }) => {
  try {
    const res = await api.delete<any>(
      `/api/admin/autocomplete/value/delete?id=${data}`,
    );

    return { data: res };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Получение полей автокомплита
 * */
export const getAutocompleteFields = request.custom(async ({ api, data }) => {
  try {
    const res = await api.get<any>(
      `/api/admin/autocomplete/list?count=1000&page=1&sort[0][id]=createdAt&sort[0][value]=ASC&filters[0][id]=field&filters[0][value]=${data}`,
    );

    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});
