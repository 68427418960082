<template>
  <div class="phone-footer">
    <el-button 
      type="primary"
      :disabled="nextDisabled"
      :class="{ 'phone-footer__grey': nextDisabled }"
      @click="onChangeNext"
    >
      <strong>{{ nextTitle }}</strong>
    </el-button>

    <el-button 
      v-if="modelValue > 1" 
      class="phone-footer__grey" 
      @click="onChangePrev"
    >
      {{ translateText('gaz.interview.preview.form.prev_button') }}
    </el-button>
  </div>
</template>
    
<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { translate, useTranslate } from 'magner';

import { ElMessage } from 'element-plus'
    
export default defineComponent({
  name: 'IntreviewPreviewFooter',
  
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup (props, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const { customT } = useTranslate();

    const translateText = (code: string) => customT(translate(code));

    const nextTitle = computed(() => {
      if (props.modelValue === props.length) {
        return translateText('gaz.interview.preview.form.final_button');
      }

      return translateText('gaz.interview.preview.form.next_button');
    });

    const scrollTop = () => {
      document.querySelector('.interview-preview-body')?.scrollTo(0, 0);
    };

    const onChangeNext = () => {
      if (props.modelValue === props.length) {
        ElMessage({
          type: 'success',
          message: translateText('gaz.interview.preview.form.done'),
        });

        return;
      }

      emit('update:modelValue', props.modelValue + 1);

      scrollTop();
    };

    const onChangePrev = () => {
      emit('update:modelValue', props.modelValue - 1);

      scrollTop();
    };

    return {
      nextTitle,

      onChangeNext,
      onChangePrev,

      translateText,
    };
  },
});
</script>
<style lang="scss" scoped>
.phone-footer {
  padding-top: 12px;
  padding-bottom: 18px;
  
  &__grey {
    background-color: rgba(248, 248, 248, 1) !important;
    border: none !important;
    color: rgba(150, 150, 150, 1) !important;

    &:hover {
      color: rgba(150, 150, 150, 0.6) !important;
    }

    &:active {
      color: rgba(150, 150, 150, 0.8) !important;
    }
  }
}
</style>
